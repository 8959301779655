/** @jsxImportSource theme-ui */
import { RefObject, useEffect, useState } from 'react'
import { AspectRatio } from 'theme-ui'
import { H6 } from 'src/components/atoms'
import { Video } from 'src/components/atoms/Video'

export type VideoPlayerProps = {
  width: number
  height: number
  sources: [{ src: string }]
  videoRef?: RefObject<HTMLVideoElement>
  autoplay?: boolean
  muted?: boolean
  poster?: string
  showSoundToggle?: boolean
  loadOnUserEvent?: boolean
}

const SOUND_ON = 'SOUND ON'
const SOUND_OFF = 'SOUND OFF'

const VideoPlayer = ({
  sources,
  width,
  height,
  videoRef,
  autoplay = true,
  muted = true,
  poster,
  showSoundToggle = false,
  loadOnUserEvent,
}: VideoPlayerProps) => {
  const src = sources?.[0].src

  const [audioMuted, setAudioMuted] = useState(muted)
  useEffect(() => setAudioMuted(muted), [muted])

  return (
    <AspectRatio ratio={width / height}>
      <Video
        sx={{ width: '100%', height: '100%' }}
        autoPlay={autoplay}
        playsInline
        loop
        muted={audioMuted}
        src={src}
        controlsRef={videoRef}
        {...(poster ? { poster } : {})}
        loadOnUserEvent={loadOnUserEvent}
      />
      {showSoundToggle ? (
        <H6
          as="div"
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            pr: 'm',
            pb: 'm',
            color: 'white',
            userSelect: 'none',
            cursor: 'pointer',
            zIndex: 2000,
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            setAudioMuted((prev) => !prev)
          }}
        >
          {audioMuted ? SOUND_ON : SOUND_OFF}
        </H6>
      ) : null}
    </AspectRatio>
  )
}

export default VideoPlayer
