/** @jsxImportSource theme-ui */

export const DELAY_AFTER_SHUTTER = 0.6
export const DURATION_OPACITY = 0.3
export const DURATION_Y = 1.2
export const DURATION_SHUTTER = 1.0
export const OFFSET_Y = '60px'
export const OFFSET_CARDS_Y = '300px'
export const EASE_OUT = [0.07, 0, 0, 0.99]
export const STAGGER_TIME = 0.175
export const STAGGER_TIME_CARDS = 0.15

type motionOpts = {
  initialY?: number | string
  delay?: number
  durationY?: number
}

export const yOpacityProps = (opts: motionOpts = {}) => {
  const initialY = opts.initialY || OFFSET_Y
  const delay = opts.delay || 0
  const durationY = opts.durationY || DURATION_Y

  return {
    initial: { y: initialY, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: {
      opacity: {
        duration: DURATION_OPACITY,
        delay,
        ease: 'linear',
      },
      y: {
        duration: durationY,
        delay,
        ease: [0.07, 0, 0, 0.99],
      },
    },
  }
}

export const xSizeProps = (opts: motionOpts = {}) => {
  const delay = opts.delay || 0

  return {
    initial: { width: '0%' },
    animate: { width: '100%' },
    transition: { duration: DURATION_Y, ease: EASE_OUT, delay },
  }
}

export const variantEmptyContainer = () => {
  return {
    hidden: {},
    show: {
      transition: {
        delayChildren: DELAY_AFTER_SHUTTER,
        staggerChildren: STAGGER_TIME,
      },
    },
  }
}

export const vY = {
  hidden: { y: OFFSET_Y, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: DURATION_Y,
      ease: EASE_OUT,
    },
  },
}

export const vImageY = {
  hidden: { y: OFFSET_CARDS_Y, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: DURATION_Y,
      ease: EASE_OUT,
    },
  },
}

export const variantFadeY = () => {
  return vY
}

export const variantSizeX = {
  hidden: { width: '0%' },
  show: {
    width: '100%',
    transition: { duration: DURATION_Y, ease: EASE_OUT },
  },
}

export const vO = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: DURATION_OPACITY, ease: 'linear' },
  },
}

export const variantY = (durationFactor = 1, yHidden = '100vh') => {
  return {
    hidden: { y: yHidden },
    show: {
      y: 0,
      transition: { duration: 0.8 * durationFactor, ease: 'easeOut' },
    },
  }
}

export const variantFade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 1, ease: 'easeOut' },
  },
}

/** Using variantFadeY or other animations that animate the y value of an
 * element is causing the scroll to move. This is a workaround. Place it before
 * the first animated component */
export const AnimationScrollYFix = () => {
  return <div sx={{ height: 0, opacity: 0 }}>_</div>
}

// Animations used for Project cards and Industrial Editorial Text component

/**
 *
 * @param param0
 * @returns
 */
export const cardsAnimationY = ({
  i,
  y,
  delay = 0,
  maxDelay = Infinity,
}: {
  i: number
  y: string
  delay?: number
  maxDelay?: number
}) => ({
  y,
  transition: {
    duration: DURATION_Y,
    delay: Math.min(delay + STAGGER_TIME_CARDS * i, maxDelay),
    ease: [0.07, 0, 0, 0.99],
  },
})

/**
 *
 * @param param0
 * @returns
 */
export const cardsAnimationOpacity = ({
  i,
  opacity,
  delay = 0,
  maxDelay = Infinity,
}: {
  i: number
  opacity: number
  delay?: number
  maxDelay?: number
}) => ({
  opacity: opacity,
  transition: {
    duration: DURATION_OPACITY,
    delay: Math.min(delay + STAGGER_TIME_CARDS * i, maxDelay),
    ease: 'linear',
  },
})
