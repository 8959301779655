import { IGatsbyImageData, Layout } from 'gatsby-plugin-image'
import { PrismicImageType } from 'root/graphql-types'

export type ImageProp = {
  alt?: string | null
  fluid: {
    src: string
    srcSet: string
    sizes?: string
    srcSetWebp: string
    base64: string
  }
  dimensions: {
    width: number
    height: number
  }
}

/**
 * Converts a prismic image data to the IGatsbyImageData used in <GatsbyImage>
 * component
 *
 * NOTE: GatsbyImage has chanced in Gatsby 3, however, Prismic still uses
 * Gatsby 2 images specs.
 * https://www.gatsbyjs.com/docs/reference/release-notes/image-migration-guide
 * https://github.com/angeloashmore/gatsby-source-prismic/blob/main/docs/migrating-from-v2-to-v3.md
 */
export const getPrismicImage = (
  image: ImageProp | PrismicImageType | undefined,
  layout: Layout = 'constrained',
  backgroundColor: string
) => {
  const imageData: IGatsbyImageData = {
    layout,
    backgroundColor,
    images: {
      fallback: {
        src: image?.fluid?.src || '',
        srcSet: image?.fluid?.srcSet || '',
        sizes: '',
      },
      sources: [
        {
          srcSet: image?.fluid?.srcSetWebp || '',
          type: '',
          sizes: '',
        },
      ],
    },
    placeholder: {
      fallback: image?.fluid?.base64 || '',
    },
    width: image?.dimensions?.width || 1,
    height: image?.dimensions?.height || 1,
  }

  return imageData
}
