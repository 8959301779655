import _ from 'lodash'

const homePageSchema = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'HY.AM STUDIOS',
  legalName: 'hy.am studios GmbH',
  url: 'https://hyam.de',
  logo: 'https://hyam.de/hyam-logo.jpg',
  image: 'https://hyam.de/hyam-logo.jpg',
  sameAs: [
    'https://www.instagram.com/hyamstudiosberlin/',
    'https://www.linkedin.com/company/hyamstudios',
  ],
  telephone: '+49 30 25783510',
  email: 'hello@hyam.de',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Schönhauser Allee 149',
    addressLocality: 'Berlin',
    postalCode: '10435',
    addressCountry: 'Germany',
  },
  foundingDate: '2017',
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    telephone: '+49 30 25783510',
    email: 'hello@hyam.de',
  },
}

export type GetSchemaProps = {
  url: string
  pathname?: string | null
  title: string
  image: string
  schema_type?: string
  faqSchemaItems?: any
}

const pagesSchema = ({ url, title, image }: GetSchemaProps) => ({
  '@context': 'http://www.schema.org',
  '@type': 'WebPage',
  name: 'hy.am studios GmbH',
  alternateName: 'HY.AM STUDIOS',
  primaryImageOfPage: image,
  url,
  breadcrumb: {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: title,
        item: url,
      },
    ],
  },
})

const faqSchema = (props: GetSchemaProps) => {
  const pageSchema = pagesSchema(props)

  return {
    ...pageSchema,
    '@type': 'FAQPage',
    "mainEntity": props?.faqSchemaItems
  }
}

export const getSchema = (props: GetSchemaProps) => {
  const isHomePage = _.includes(
    [undefined, null, '/', '/en', '/en/', '/de', '/de/'],
    props.pathname
  )

  if (props?.schema_type === 'FAQPage' && !_.isEmpty(props?.faqSchemaItems)) {
    return faqSchema(props)
  }

  return isHomePage ? homePageSchema : pagesSchema(props)
}
