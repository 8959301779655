/** @jsxImportSource theme-ui */
import { FC, useEffect, useState, memo } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import _ from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import {
  motion,
  AnimatePresence,
  useAnimation,
  Transition as MotionTransition,
} from 'framer-motion'
import { Global } from '@emotion/react'
import { navigate } from '@reach/router'
import isbot from 'isbot'

import { Hero, MailChimpSignUp } from 'src/components/organisms'
import { EE } from 'src/utils/eventEmitter'
import NavBarMobileMenu from 'src/components/organisms/NavBar/NavBarMobileMenu'

import { Query, PrismicBottomBlockDataType } from 'root/graphql-types'
import { MenuLink, NavBarWithScroll } from 'src/components/organisms/NavBar'
import { Footer, BottomBlock } from 'src/components/organisms/Footer'
import { sm, ml } from 'src/gatsby-plugin-theme-ui/utils'
import { getLang } from 'src/utils/languageSwitch'
import menuConfig from 'src/generated/menuConfig.json'
import { DURATION_SHUTTER } from 'src/animations'
import smoothScroll from 'src/utils/smoothScroll'
import { getUserLang } from 'src/utils/languageSwitch'
import { isBrowser } from 'src/utils/viewport'
import { useMobileViewportHeight } from 'src/utils/useMobileViewportHeight'
import { InvisibleGridGuide } from 'src/components/atoms/layout'

const navBarHeight = sm('navBarHeightMobile', 'navBarHeightDesktop')

export const isPageLoaded = () => {
  // @ts-ignore
  return isBrowser() && !!window?.__pageIsLoaded__
}
const setPageIsLoaded = () => {
  if (isBrowser()) {
    // @ts-ignore
    window.__pageIsLoaded__ = true
  }
}

const isFirstTimeHomePage = (path: string) => {
  return !isPageLoaded() && (path === '/' || path === '/en')
}

const localizeMenuLinks = (menuLinks: MenuLink[], lang: string) => {
  return _.map(menuLinks, ({ name, link }) => {
    return {
      name: _.get(menuConfig, `${name}.${lang}.title`) || name,
      link: _.get(menuConfig, `${name}.${lang}.slug`) || link,
    }
  })
}

const showIubendaBanner = () => {
  if (typeof document !== 'object') return

  const r = document.querySelector(':root') as HTMLDivElement

  if (!r) return

  setTimeout(() => {
    r.style.setProperty('--iubenda-opacity', '1')
    r.style.setProperty('--iubenda-transform', 'translateY(0px)')
  }, 2000)
}

const needsRedirectHomePageToEnglish = (path: string) => {
  if (path !== '/') return false

  // Skip on SEO crawler
  if (isbot(navigator.userAgent)) return false

  const lang = getUserLang()

  if (!lang.match(/^de/)) {
    return true
  }

  return false
}

const redirectHomePageToEnglish = (path: string) => {
  if (needsRedirectHomePageToEnglish(path)) {
    navigate(`/en`, { replace: true, state: { isLanguageRedirect: true } })
    return true
  }

  return false
}

const Transition: FC<{
  path: string
  isLanguageRedirect: boolean
  disableShutter?: boolean
}> = memo(({ children, path, isLanguageRedirect, disableShutter = false }) => {
  const [exitBeforeEnter, setExitBeforeEnter] = useState(false)

  const shutter: MotionTransition = {
    duration: DURATION_SHUTTER,
    times: exitBeforeEnter ? [0, 0.4, 0.6, 1] : [0, 1],
    ease: [0.76, 0, 0.24, 1],
  }
  const shutterSx: ThemeUIStyleObject = {
    bg: 'black',
    position: 'fixed',
    left: 0,
    zIndex: 5000,
    height: '51vh',
    width: '100vw',
  }
  const controlsTop = useAnimation()
  const controlsBottom = useAnimation()

  useEffect(() => {
    const doShutterAnimations = async () => {
      if (redirectHomePageToEnglish(path)) {
        setTimeout(() => EE.emit('hide-ssr-black-screen'), 1000)
        return
      } else if (isFirstTimeHomePage(path)) {
        // SKIP
      } else if (!isLanguageRedirect) {
        setTimeout(() => EE.emit('hide-ssr-black-screen'), 400)
      } else {
        setTimeout(() => EE.emit('hide-ssr-black-screen'), 1000)
      }

      // First time home page
      if (isFirstTimeHomePage(path)) {
        EE.on('homepage-hero-text-animation-done', async () => {
          setTimeout(() => EE.emit('hide-ssr-black-screen'), 1)
          controlsTop.start({ y: ['49vh', '49vh', '-1vh'] })
          await controlsBottom.start({ y: ['-51vh', '-51vh', '0vh'] })

          EE.emit('shutter-animation-done')
        })
      }
      // Page transition
      else if (isPageLoaded()) {
        setExitBeforeEnter(true)

        setTimeout(
          () => EE.emit('smooth-scroll-set-scroll', 0),
          shutter.duration * 600
        )

        controlsTop.start({ y: ['-1vh', '49vh', '49vh', '-1vh'] })
        await controlsBottom.start({ y: ['0vh', '-51vh', '-51vh', '0vh'] })
        EE.emit('shutter-animation-done')
      }
      // First time non home page
      else {
        controlsTop.start({ y: ['49vh', '49vh', '-1vh'] })
        await controlsBottom.start({ y: ['-51vh', '-51vh', '0vh'] })
        EE.emit('shutter-animation-done')
      }
    }

    doShutterAnimations()

    setTimeout(() => {
      if (isPageLoaded()) {
        setExitBeforeEnter(true)
      }
    }, 1000)
  }, [path, controlsTop, controlsBottom, shutter.duration]) // eslint-disable-line

  const shutterTransition: MotionTransition = {
    ease: shutter.ease,
    duration: shutter.duration,
    times: shutter.times,
  }

  return (
    <div
      onMouseDown={() => EE.emit('global-mouse-down')}
      onMouseUp={() => EE.emit('global-mouse-up')}
    >
      <motion.div
        animate={controlsTop}
        transition={shutterTransition}
        sx={{
          ...shutterSx,
          top: '-50vh',
          display: disableShutter ? 'none' : 'block',
        }}
      />
      <motion.div
        animate={controlsBottom}
        transition={shutterTransition}
        sx={{
          ...shutterSx,
          top: '100vh',
          display: disableShutter ? 'none' : 'block',
        }}
      />
      <AnimatePresence exitBeforeEnter={exitBeforeEnter}>
        <motion.div
          key={path + '_children'}
          initial={{
            opacity: 1,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 1,
          }}
          transition={{
            duration: shutter.duration / 2,
            ease: 'easeIn',
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  )
})

type MainLayoutProps = {
  children?: React.ReactNode
  navTextColor: string
  disableShutter?: boolean
  bottomBlock?: PrismicBottomBlockDataType
  hideBottomBlock?: boolean
  hero?: any
  pageContext: any
  path: string
  location: any
}

const __pathsHistory: string[] = []

/**
 * @returns {boolean} true if the user only visited one page so far
 *                    false if the user navigated among different pages
 */
const isFirstPathVisited = (path: string) => {
  if (__pathsHistory.length === 0) {
    __pathsHistory.push(path)
    return true
  }

  const lastIndex = __pathsHistory.length - 1
  const lastPath = __pathsHistory[lastIndex]

  // don't push the same path twice
  if (path !== lastPath) {
    __pathsHistory.push(path)
  }

  return __pathsHistory.length <= 1
}

const MainLayout: FC<MainLayoutProps> = ({ children, path, ...props }) => {
  useMobileViewportHeight()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showSsrBlackScreen, setShowSsrBlackScreen] = useState(true)
  const isLanguageRedirect = props?.location?.state?.isLanguageRedirect

  const layout = props?.pageContext?.__layout || {}
  const {
    hideBottomBlock = false,
    ignoreLayout = false,
    navTextColor = 'text',
    disableShutter: _disableShutter = false,
    bottomBlock = null,
    heroProps = {},
  } = layout

  const disableShutter = isFirstPathVisited(path) ? _disableShutter : false

  const heroJsx = (() => {
    if (!_.isEmpty(heroProps)) {
      return (
        <Hero
          {...heroProps}
          hideSoundOn={path === '/' || path === '/en'}
          sx={{ mb: ml('xl', 'xxl') }}
        />
      )
    }

    return null
  })()

  const siteData: Query = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const menuLinks: MenuLink[] = _.get(
    siteData,
    'site.siteMetadata.menuLinks',
    []
  )

  useEffect(() => {
    EE.on('hide-ssr-black-screen', () => {
      setShowSsrBlackScreen(false)
    })

    // if ('scrollRestoration' in window?.history)
    //   window.history.scrollRestoration = 'manual'

    // smoothScroll()

    setPageIsLoaded()
    EE.on('open-mobile-navbar', () => {
      setShowMobileMenu(true)
    })
    EE.on('close-mobile-navbar', () => {
      setShowMobileMenu(false)
    })
  }, [])

  const lang = getLang(path)
  const localizedMenuLinks = localizeMenuLinks(menuLinks, lang)
  const [disableYScroll, setDisableYScroll] = useState(false)

  useEffect(() => {
    EE.on('open-mobile-navbar', () => {
      setDisableYScroll(true)
    })
    EE.on('close-mobile-navbar', () => {
      setDisableYScroll(false)
    })
    EE.on('shutter-animation-done', () => {
      showIubendaBanner()
    })
  }, [])

  if (ignoreLayout) return <>{children}</>

  return (
    <div>
      <Global
        styles={{
          body: {
            overflowY: disableYScroll ? 'hidden' : 'auto',
          },
        }}
      />
      {!disableShutter && showSsrBlackScreen ? (
        <div
          sx={{
            bg: 'black',
            opacity: showSsrBlackScreen ? 1 : 0,
            transition: `opacity 0.3s linear`,
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 20000,
            position: 'fixed',
          }}
        />
      ) : null}
      <NavBarMobileMenu show={showMobileMenu} menuLinks={localizedMenuLinks} />
      <Transition
        disableShutter={disableShutter}
        path={path}
        isLanguageRedirect={!!isLanguageRedirect}
      >
        <motion.div>
          <div key={navTextColor /* forces redraw on navTextColor change */}>
            <NavBarWithScroll
              menuLinks={localizedMenuLinks}
              navTextColor={navTextColor}
              hasHero={!!heroJsx}
              path={path}
            />
            {!!heroJsx && <div>{heroJsx}</div>}
          </div>
        </motion.div>

        <div
          sx={{
            mx: [
              'xsGridMargin',
              'sGridMargin',
              'mGridMargin',
              'lGridMargin',
              'auto',
            ],
            maxWidth: '1640px',
            pt: navBarHeight,
          }}
        >
          <InvisibleGridGuide />
          {children}
        </div>
        <div
          sx={{
            px: [
              'xsGridMargin',
              'sGridMargin',
              'mGridMargin',
              'lGridMargin',
              'auto',
            ],
            backgroundColor: 'footer',
          }}
        >
          {!hideBottomBlock && <BottomBlock data={bottomBlock} lang={lang} />}
          <Footer lang={lang} hideTopThinLine={hideBottomBlock} />
          <MailChimpSignUp />
        </div>
      </Transition>
    </div>
  )
}

export default memo(MainLayout)
