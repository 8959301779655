/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'

const styles: Record<string, ThemeUIStyleObject> = {}

styles.container = {
  // backgroundColor: 'yellow',
  width: 24,
  height: 32,
}

styles.left = {
  position: 'absolute',
  top: 0,
  left: 11.75,
  height: 17,
  width: 1,
  backgroundColor: 'black',
  transform: 'rotate(45deg)',
  transformOrigin: 'top left',
  transition: 'all .2s ease',
}

styles.leftClose = {
  ...styles.left,
  transform: 'rotate(0deg)',
  opacity: 0,
}

styles.right = {
  ...styles.left,
  left: 11,
  transform: 'rotate(-45deg)',
  transformOrigin: 'top right',
}

styles.rightClose = {
  ...styles.right,
  transform: 'rotate(0deg)',
  opacity: 0,
}

styles.center = {
  position: 'absolute',
  left: 11.5,
  top: 1,
  height: 31,
  width: 1,
  backgroundColor: 'black',
}

styles.leftDown = {
  ...styles.left,
  top: 31,
  transform: 'rotate(135deg)',
}

styles.leftDownClose = {
  ...styles.leftDown,
  opacity: 0,
  transform: 'rotate(180deg)',
}

styles.rightDown = {
  ...styles.right,
  top: 31,
  transform: 'rotate(-135deg)',
}

styles.rightDownClose = {
  ...styles.rightDown,
  opacity: 0,
  transform: 'rotate(-180deg)',
}

export type AnimatedUpDownArrowProps = {
  open: boolean
}

export const AnimatedUpDownArrow: FC<AnimatedUpDownArrowProps> = ({ open }) => {
  return (
    <div sx={styles.container}>
      <div sx={open ? styles.left : styles.leftClose} />
      <div sx={open ? styles.leftDownClose : styles.leftDown} />
      <div sx={styles.center} />
      <div sx={open ? styles.right : styles.rightClose} />
      <div sx={open ? styles.rightDownClose : styles.rightDown} />
    </div>
  )
}
