/**
 * Check if an element is in viewport
 *
 * @param {el} [element]
 * @returns {boolean} boolean
 */
export const isInViewport = (el: HTMLDivElement | null) => {
  if (!el || !isBrowser()) return false
  const { top } = el.getBoundingClientRect()
  return top - (window.innerHeight || document.documentElement.clientHeight) < 0
}

export const isBrowser = () => typeof window !== 'undefined'
