// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-de-404-tsx": () => import("./../../../src/pages/de/404.tsx" /* webpackChunkName: "component---src-pages-de-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-templates-aboutpage-tsx": () => import("./../../../src/templates/aboutpage.tsx" /* webpackChunkName: "component---src-templates-aboutpage-tsx" */),
  "component---src-templates-contactpage-tsx": () => import("./../../../src/templates/contactpage.tsx" /* webpackChunkName: "component---src-templates-contactpage-tsx" */),
  "component---src-templates-culturepage-tsx": () => import("./../../../src/templates/culturepage.tsx" /* webpackChunkName: "component---src-templates-culturepage-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-imprintpage-tsx": () => import("./../../../src/templates/imprintpage.tsx" /* webpackChunkName: "component---src-templates-imprintpage-tsx" */),
  "component---src-templates-jobpost-tsx": () => import("./../../../src/templates/jobpost.tsx" /* webpackChunkName: "component---src-templates-jobpost-tsx" */),
  "component---src-templates-jobspage-tsx": () => import("./../../../src/templates/jobspage.tsx" /* webpackChunkName: "component---src-templates-jobspage-tsx" */),
  "component---src-templates-landingpage-tsx": () => import("./../../../src/templates/landingpage.tsx" /* webpackChunkName: "component---src-templates-landingpage-tsx" */),
  "component---src-templates-privacypolicypage-tsx": () => import("./../../../src/templates/privacypolicypage.tsx" /* webpackChunkName: "component---src-templates-privacypolicypage-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */)
}

