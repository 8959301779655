/** @jsxImportSource theme-ui */
import { FC, useState, memo, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { isMobile } from 'react-device-detect'

const animationControlIn = {
  opacity: [0, 1],
  scale: [0.7, 1],
  transition: {
    opacity: { duration: 0.05 },
    scale: { duration: 0.3 },
    ease: [0.45, 0, 0.55, 1],
  },
}

const animationControlOut = {
  opacity: 0,
  scale: 0.7,
  transition: {
    opacity: { duration: 0.1 },
    scale: { duration: 0.1 },
    ease: [0.07, 0, 0, 0.99],
  },
}

type ComponentWithCursorProps = {
  component: any
  cursorJsx: any
  cursorX: number
  cursorY: number
  useRelativeToElementPosition?: boolean
}

const ComponentWithCursorDesktop: FC<ComponentWithCursorProps> = ({
  component,
  cursorJsx,
  cursorX,
  cursorY,
  useRelativeToElementPosition = false,
}) => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const [isHovered, setHovered] = useState(false)
  const animationControl = useAnimation()

  const onMouseMove = (e: any) => {
    const cursorEl = cursorRef?.current
    if (!cursorEl) return

    const { top, left, right, bottom } = e.target.getBoundingClientRect()

    if (useRelativeToElementPosition) {
      cursorEl.style.top = e.clientY - cursorY - top + 'px'
      cursorEl.style.left = e.clientX - cursorX - left + 'px'
    } else {
      cursorEl.style.top = e.clientY - cursorY + 'px'
      cursorEl.style.left = e.clientX - cursorX + 'px'
    }

    const distance = {
      top: e.clientY - top,
      left: e.clientX - left,
      right: right - e.clientX,
      bottom: bottom - e.clientY,
    }

    // NOTE: 20 makes the hover area 20 pixels wider
    const isInside =
      distance.top > cursorY - 20 &&
      distance.bottom > cursorY - 20 &&
      distance.left > cursorX - 20 &&
      distance.right > cursorX - 20

    if (isInside === isHovered) return

    if (isInside) {
      animationControl.start(animationControlIn)
    } else {
      animationControl.start(animationControlOut)
    }

    setHovered(isInside)
  }

  return (
    <div>
      <div
        onMouseMove={onMouseMove}
        onMouseLeave={() => {
          animationControl.start(animationControlOut)
          setHovered(false)
        }}
        sx={{ cursor: isHovered ? 'none' : 'auto' }}
      >
        {component}
      </div>
      <motion.div
        ref={cursorRef}
        animate={animationControl}
        sx={{
          opacity: 0,
          position: 'fixed',
          zIndex: 1000,
          pointerEvents: 'none',
          cursor: 'none',
        }}
      >
        {cursorJsx}
      </motion.div>
    </div>
  )
}

const MemoComponentWithCursorDesktop = memo(ComponentWithCursorDesktop)

const ComponentWithCursor: FC<ComponentWithCursorProps> = ({
  component,
  cursorJsx,
  cursorX,
  cursorY,
  useRelativeToElementPosition = false,
}) => {
  if (isMobile) return component

  return (
    <MemoComponentWithCursorDesktop
      component={component}
      cursorJsx={cursorJsx}
      cursorX={cursorX}
      cursorY={cursorY}
      useRelativeToElementPosition={useRelativeToElementPosition}
    />
  )
}

export default ComponentWithCursor
