import { useState } from 'react'

function useLocalStorage<T>(key: string, initialValue: T) {
  const getValue = () => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  }

  const [storedValue, setStoredValue] = useState<T>(() => {
    return getValue()
  })

  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {}
  }

  return [storedValue, setValue, getValue] as [T, (val: T) => void, () => T]
}

export default useLocalStorage
