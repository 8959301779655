import { getLang as _getLang } from 'src/utils/languageSwitch'
import getPath from 'src/utils/getPath'
import _ from 'lodash'

export const getLang = (node: any) => {
  if (node?.lang === 'de-de' || node?.lang === 'de') return 'de'
  if (node?.lang === 'en-gb' || node?.lang === 'en') return 'en'

  return _getLang()
}

const linkResolver = (doc: any) => {
  if (_.isEmpty(doc)) return '/'

  const props = doc._meta || doc
  const lang = getLang(props)
  const type = props?.type
  const uid = props?.uid
  const slug = props?.slug

  if (slug === 'imprint-page') {
    return getPath(lang, 'imprint')
  }
  if (slug === 'privacy-policy-page') {
    return getPath(lang, 'privacy_policy')
  }
  if (type === 'homepage') {
    return `/${lang}`
  }
  if (type === 'work_page') {
    return getPath(lang, 'work')
  }
  if (type === 'contact') {
    return getPath(lang, 'contact')
  }

  if (uid) return getPath(lang, uid)

  return '/'
}

export default linkResolver
