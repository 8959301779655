import EventEmitter from 'eventemitter3'

const _EE = new EventEmitter()

export const EE = {
  on: (eventName: string, cb: () => void) => {
    _EE.on(eventName, cb)
  },
  off: (eventName: string, cb: () => void) => {
    _EE.off(eventName, cb)
  },
  emit: (eventName: string, data?: any) => {
    _EE.emit(eventName, data)
  },
}
