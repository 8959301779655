import { useEffect, useRef } from 'react'

/**
 * Solution taken from: https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
 */
export const useMobileViewportHeight = () => {
  const vh = useRef(0)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const documentHeight = () => {
      // only get height once
      if (vh.current > 0) return
      if (window.innerHeight > 0) vh.current = window.innerHeight

      const doc = document.documentElement
      doc.style.setProperty('--mobile-vh', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', documentHeight)

    documentHeight()
  }, [])
}
