import { ThemeUIStyleObject } from 'theme-ui'

export const heroSx: ThemeUIStyleObject = {
  position: 'relative',
  minHeight: '100vh',
}

export const heroContentSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  zIndex: '1',
  overflow: 'hidden',
  backgroundSize: 'cover',
}

export const heroVideoSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  zIndex: '-100',
  transform: 'translateX(-50%) translateY(-50%)',
  overflow: 'hidden',
}

export const soundOnSx: ThemeUIStyleObject = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  pr: 'm',
  pb: 'm',
  color: 'white',
  userSelect: 'none',
  cursor: 'pointer',
  zIndex: 2000,
}
