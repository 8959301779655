import { useGridColumnsWidth } from 'src/utils/useGridColumnsWidth'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'

/**
 * @returns Collection of predefined stylings options for the RichText
 * sxElements property
 */
export const useRichTextCustomStyles = () => {
  const getColumnsWidth = useGridColumnsWidth()

  const cols10 = {
    width: ml('100%', getColumnsWidth(10)),
  }
  const cols8 = {
    width: ml('100%', getColumnsWidth(8)),
  }

  return {
    headings10ColsElements8Cols: {
      h1: cols10,
      h2: cols10,
      h3: cols10,
      h4: cols10,
      h5: cols10,
      h6: cols10,
      paragraph: cols8,
    },
    all10Cols: {
      h1: cols10,
      h2: cols10,
      h3: cols10,
      h4: cols10,
      h5: cols10,
      h6: cols10,
      paragraph: cols10,
    },
  }
}
