/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Flex, useThemeUI } from 'theme-ui'
import { Link } from 'gatsby'
// @ts-ignore
import Hamburger from 'src/svg/hamburger.svg'
import { navigation } from 'src/gatsby-plugin-theme-ui/text'
import { EE } from 'src/utils/eventEmitter'
// @ts-ignore
import LogoLight from 'src/svg/hyam_logo_light.svg'
// @ts-ignore
import LogoDark from 'src/svg/hyam_logo_dark.svg'
import { getPath } from './NavBarDesktop'

const logos: Record<string, any> = {
  text: LogoDark,
  background: LogoLight,
}

const NavBarMobile: FC<{ navTextColor: string }> = ({ navTextColor }) => {
  const { theme } = useThemeUI()
  const themeNavTextColor = theme?.colors?.[navTextColor]

  const [redrawKey, setRedrawKey] = useState('nav_bar_mobile')

  // Safari (mobile and desktop) and mobile Chrome have issues rendering SVGs.
  // The workaround here is to force a re-draw by changing the key attribute
  useEffect(() => {
    setTimeout(() => setRedrawKey('nav_bar_mobile_redraw'), 0)
  }, [])

  const Logo = logos[navTextColor]

  return (
    <div key={redrawKey}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 'navBarHeightMobile',
        }}
      >
        <Link
          to={getPath('')}
          sx={{
            ...navigation,
            color: navTextColor,
            mt: '4px', // fine tune horizontal centering
          }}
        >
          <Logo />
        </Link>
        <Hamburger
          onClick={() => EE.emit('open-mobile-navbar')}
          fill={themeNavTextColor}
          sx={{ color: navTextColor }}
        />
      </Flex>
    </div>
  )
}

export default NavBarMobile
