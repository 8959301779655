/** @jsxImportSource theme-ui */
import { FC } from 'react'
import _ from 'lodash'
import { Flex } from 'theme-ui'
import { Link } from 'gatsby'
import { H4, Body, CtaPrimary } from 'src/components/atoms/text'
import linkResolver from 'src/utils/linkResolver'
import { toReact } from 'src/utils/htmlReactParser'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { dataLayerPush } from 'src/utils/gtm'
import { Maybe, PrismicAllDocumentTypes } from 'graphql-types'
// @ts-ignore
import ArrowSmall from 'src/svg/arrow_small.svg'
import service from 'src/templates/service'
import { RichText } from 'src/components/organisms'

const SERVICES_ITEM_ARROW = 'services-item-arrow'

const ServicesItem: FC<{
  title: string
  description: string
  descriptionRichText: any
  seeMoreLabel?: string
  link?: Maybe<PrismicAllDocumentTypes> | undefined
}> = ({ title, description, seeMoreLabel, link, descriptionRichText }) => {
  return (
    <div
      sx={{
        textDecoration: 'none',
      }}
    >
      <Flex
        sx={{
          alignItems: 'top',
          mb: ml('s', 'm'),
        }}
      >
        <H4>{title}</H4>
      </Flex>
      <div
        sx={{
          mb: ml('l', 'l2'),
          p: { my: 0 },
        }}
      >
        {descriptionRichText ? (
          <RichText newHeadingsStyles content={descriptionRichText} />
        ) : (
          toReact(description, Body)
        )}
        {!_.isEmpty(link) ? (
          <Link sx={{ textDecoration: 'none' }} to={linkResolver(link)}>
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 'm2',
                textTransform: 'uppercase',
                ':hover': {
                  [`.${SERVICES_ITEM_ARROW}`]: {
                    transform: 'translateX(16px)',
                  },
                },
              }}
              onClick={() => {
                dataLayerPush({
                  event: 'Click',
                  Text: (link as any)?.data?.name,
                })
              }}
            >
              <CtaPrimary sx={{ paddingTop: '5px' }}>{seeMoreLabel}</CtaPrimary>
              <ArrowSmall
                className={SERVICES_ITEM_ARROW}
                sx={{
                  ml: 's',
                  verticalAlign: 'top',
                  transition: `all 0.5s ease`,
                }}
              />
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default ServicesItem
