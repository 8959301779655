/** @jsxImportSource theme-ui */
import { FC } from 'react'
import _ from 'lodash'
import { Flex } from 'theme-ui'
import SineWaveSvg from 'src/svg/sine_wave.svg'

const SineWave: FC<{ width: number; color: string }> = ({ width, color }) => {
  if (!width) return null

  return (
    <Flex
      sx={{
        width,
        justifyContent: 'space-between',
        flexDirection: 'row',
        transform: 'translateY(12px)',
      }}
    >
      {_.times((width + 20) / 20, (i) => (
        <SineWaveSvg
          key={i}
          stroke={color}
          sx={{
            display: 'inline-block',
            width: width,
            height: '64px',
          }}
        />
      ))}
    </Flex>
  )
}
export default SineWave
