/** @jsxImportSource theme-ui */
import { FC, useEffect, useState, memo } from 'react'
// @ts-ignore
import useScroll from '@react-hooks-custom/use-scroll'
import NavBarDesktop from './NavBarDesktop'
import NavBarMobile from './NavBarMobile'
import { NavBarProps } from './types'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'
import { MenuLink } from './types'

const navBarHeightMinus = sm('-navBarHeightMobile', '-navBarHeightDesktop')

const NavBar: FC<NavBarProps> = ({ menuLinks, navTextColor, path }) => {
  return (
    <div>
      <div sx={{ display: sm('none', 'block') }}>
        <NavBarDesktop
          path={path}
          menuLinks={menuLinks}
          navTextColor={navTextColor}
        />
      </div>
      <div sx={{ display: sm('block', 'none') }}>
        <NavBarMobile navTextColor={navTextColor} />
      </div>
    </div>
  )
}

/**
 * Memoized NavBar container to ensure that nav bar doesn't get rerendered too
 * many times while hide/show animation
 */
const NavBarContainer: FC<{
  menuLinks: MenuLink[]
  navTextColor: string
  navBackgroundColor: string
  navBarTop: string | string[]
  path: string
}> = memo(
  ({ menuLinks, navTextColor, navBarTop, navBackgroundColor, path }) => {
    return (
      <div
        sx={{
          px: [
            'xsGridMargin',
            'sGridMargin',
            'mGridMargin',
            'lGridMargin',
            'calc((100% - 1640px) / 2)',
          ],
          bg: navBackgroundColor,
          position: 'fixed',
          width: '100%',
          top: navBarTop,
          left: 0,
          zIndex: 3001,
          transition: 'top 0.8s',
        }}
      >
        <NavBar path={path} menuLinks={menuLinks} navTextColor={navTextColor} />
      </div>
    )
  }
)

/**
 * Memoized NavBar component that checks for window scroll position
 */
export const NavBarWithScroll: FC<{
  menuLinks: MenuLink[]
  navTextColor: string
  hasHero: boolean
  path: string
}> = memo((props) => {
  const { hasHero } = props

  const { scrollY } = useScroll()
  const [prevScrollY, setPrevScrollY] = useState(scrollY)
  const [navBarTop, setNavBarTop] = useState<string | string[]>('0px')
  const [navTextColor, setNavTextColor] = useState(props.navTextColor || 'text')
  const [navBackgroundColor, setNavBackgroundColor] = useState(
    hasHero ? 'transparent' : 'background'
  )

  // change color of navbar if page has a hero
  useEffect(() => {
    if (!hasHero) return
    // on top of hero
    else if (scrollY > window.innerHeight) {
      setNavTextColor('text')
      setNavBackgroundColor('background')
    }
    // below hero
    else {
      setNavTextColor('background')
      setNavBackgroundColor('transparent')
    }
  }, [hasHero, scrollY])

  useEffect(() => {
    if (scrollY < 48 && navBarTop !== '0px') {
      setNavBarTop('0px')
    } else if (prevScrollY > scrollY && navBarTop !== '0px') {
      setNavBarTop('0px')
    } else if (prevScrollY < scrollY && navBarTop === '0px') {
      setNavBarTop(navBarHeightMinus)
    }
    setPrevScrollY(scrollY)
  }, [scrollY, prevScrollY, navBarTop])

  return (
    <NavBarContainer
      {...props}
      navBackgroundColor={navBackgroundColor}
      navTextColor={navTextColor}
      navBarTop={navBarTop}
    />
  )
})

export default NavBar
