//
import { fullWidthHeadingSx } from './sizes'
import { ThemeUIStyleObject } from 'theme-ui'
import { isMobile } from 'react-device-detect'

/**
 * Fill in responsive array using only medium `m` and large `l` values
 */
export function xl<L, XL>(l: L, xl: XL) {
  return [l, l, l, l, xl] as [L, L, L, L, XL]
}

/**
 * Fill in responsive array using only medium `m` and large `l` values
 */
export function ml<M, L>(m: M, l: L) {
  return [m, m, m, l, l] as [M, M, M, L, L]
}

/**
 * Fill in responsive array using only small `s` and medium `m` values
 */
export function sm<S, M>(s: S, m: M) {
  return [s, s, m, m, m] as [S, S, M, M, M]
}

/**
 * Fill in responsive array using only extra small `xs` and small `s` values
 */
export function xs<XS, S>(xs: XS, s: S) {
  return [xs, s, s, s, s] as [XS, S, S, S, S]
}

export const blockScrollYMobile = (fullWidth = false) => {
  if (!isMobile) return {} as ThemeUIStyleObject

  const sx: ThemeUIStyleObject = {
    overflow: 'hidden',
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '::-webkit-scrollbar': { display: 'none' },
  }

  if (fullWidth) return { ...sx, ...fullWidthHeadingSx }

  return sx
}
