/** @jsxImportSource theme-ui */
import { FC, memo, useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { AspectRatio } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { motion } from 'framer-motion'

import { getLang } from 'src/utils/languageSwitch'
import { Title, H3, H5, H6 } from 'src/components/atoms'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { ResponsiveAsset } from 'src/components/organisms'
import getPath from 'src/utils/getPath'
import ComponentWithCursorDesktop from 'src/components/molecules/ComponentWithCursor'
// @ts-ignore
import SeeMoreCursor from 'src/svg/see_more_cursor_green.svg'
import RotatingSvgWithEyes from 'src/components/molecules/RotatingSvgWithEyes'

type ProjectCardProps = {
  imageDesktop: any
  imageMobile?: any
  videoDesktop?: any
  videoMobile?: any
  videoResolution?: 0 | 1 | 2 | 3 | 4 | 'default'
  aspectRatio?: number
  title: string
  client: string
  slug: string
  useCursorRelativeToElementPosition?: boolean
  titleSize?: 'HOME_PAGE' | 'H3'
  mb?: number | string | string[]
}

const ProjectCard: FC<ProjectCardProps> = ({
  imageDesktop,
  imageMobile,
  aspectRatio = 1,
  videoDesktop,
  videoMobile,
  videoResolution = 'default',
  title,
  client,
  slug,
  useCursorRelativeToElementPosition = false,
  titleSize = 'H3',
  mb = 'xl',
}) => {
  const breakpoint = useBreakpointIndex()
  const assetRef = useRef<HTMLDivElement>(null)
  const [containerAspectRatio, setContainerAspectRatio] = useState(aspectRatio)

  useEffect(() => {
    const assetEl = assetRef?.current
    if (!assetEl) return

    setContainerAspectRatio(assetEl.offsetWidth / assetEl.offsetHeight)
  }, [breakpoint])

  return (
    <Link
      to={getPath(getLang(), slug)}
      sx={{ textDecoration: 'none', width: '100%' }}
    >
      <div sx={{ mb: mb }}>
        <ComponentWithCursorDesktop
          cursorJsx={
            <RotatingSvgWithEyes
              icon={<SeeMoreCursor />}
              closeEyesOnClick={true}
            />
          }
          cursorX={36}
          cursorY={36}
          useRelativeToElementPosition={useCursorRelativeToElementPosition}
          component={
            <div sx={{ mb: ml('s', 'm') }}>
              <AspectRatio
                ratio={containerAspectRatio}
                sx={{ overflow: 'hidden' }}
              >
                <motion.div
                  whileHover={{
                    scale: 1.02,
                    transition: {
                      ease: [0.5, 1, 0.89, 1],
                      duration: 1,
                    },
                  }}
                  transition={{
                    ease: [0.5, 1, 0.89, 1],
                    duration: 0.4,
                  }}
                >
                  <div ref={assetRef} sx={{ bg: '#eeeeee' }}>
                    <ResponsiveAsset
                      aspectRatio={aspectRatio}
                      imageDesktop={imageDesktop}
                      imageMobile={imageMobile}
                      videoDesktop={videoDesktop}
                      videoMobile={videoMobile}
                      videoResolution={videoResolution}
                    />
                  </div>
                </motion.div>
              </AspectRatio>
            </div>
          }
        />
        <Title as="span" sx={{ mb: 'xs' }}>
          {client || 'Client'}
        </Title>
        {titleSize === 'HOME_PAGE' && breakpoint > 0 ? (
          <H5 as="span">{title || 'Title'}</H5>
        ) : (
          <H6 as="span">{title || 'Title'}</H6>
        )}
      </div>
    </Link>
  )
}

export default memo(ProjectCard)
