/** @jsxImportSource theme-ui */
import { FC } from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import linkResolver from 'src/utils/linkResolver'
import { ThemeUIStyleObject, Text } from 'theme-ui'
import { PrismicRichText } from '@prismicio/react'
import { Body } from 'src/components/atoms/text'
import * as textStyles from 'src/gatsby-plugin-theme-ui/text'

import { body } from 'src/gatsby-plugin-theme-ui/text'

export type RichTextProps = {
  content: any
  sxContainer?: ThemeUIStyleObject
  paragraphStyleAsSpan?: boolean
  headingsAsSpan?: boolean
  headingsNoStyle?: boolean
  className?: string
  sxElements?: {
    h1?: ThemeUIStyleObject
    h2?: ThemeUIStyleObject
    h3?: ThemeUIStyleObject
    h4?: ThemeUIStyleObject
    h5?: ThemeUIStyleObject
    h6?: ThemeUIStyleObject
    paragraph?: ThemeUIStyleObject
    oListItem?: ThemeUIStyleObject
    listItem?: ThemeUIStyleObject
    hyperlink?: ThemeUIStyleObject
    image?: ThemeUIStyleObject
  }
}

/** TODO custom ordered list styling with number inside a circle */
// const styles = {
//   ol: {
//     listStyle: 'none',
//     counterReset: 'item',
//     ml: -10,
//   },
//   li: {
//     counterIncrement: 'item',
//     marginBottom: '5px',
//   },
//   'li:before': {
//     marginRight: '10px',
//     // p: 's',
//     p: 2,
//     content: 'counter(item)',
//     borderRadius: '100%',
//     borderColor: 'black',
//     borderWidth: 1,
//     borderStyle: 'solid',
//     color: 'text',
//     width: '1.2em',
//     textAlign: 'center',
//     display: 'inline-block',
//   },
// }

type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type HeadingConfigHeading = {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
  sx: ThemeUIStyleObject
}
type HeadingConfigHeadings = {
  h1: HeadingConfigHeading
  h2: HeadingConfigHeading
  h3: HeadingConfigHeading
  h4: HeadingConfigHeading
  h5: HeadingConfigHeading
  h6: HeadingConfigHeading
}
const HEADING_CONFIG: HeadingConfigHeadings = {
  h1: { as: 'h1', sx: textStyles.h1 },
  h2: { as: 'h2', sx: textStyles.h2 },
  h3: { as: 'h3', sx: textStyles.h3 },
  h4: { as: 'h4', sx: textStyles.h4 },
  h5: { as: 'h5', sx: textStyles.h5 },
  h6: { as: 'h6', sx: textStyles.h6 },
}

const RichText: FC<RichTextProps> = ({
  content,
  sxContainer,
  sxElements = {},
  paragraphStyleAsSpan = false,
  headingsAsSpan = false,
  headingsNoStyle = false,
  className,
}) => {
  const headingConfig = _.cloneDeep(HEADING_CONFIG)

  if (headingsAsSpan) {
    _.each(headingConfig, (item) => {
      item.as = 'span'
    })
  }
  if (headingsNoStyle) {
    _.each(headingConfig, (item) => {
      item.sx = {}
    })
  }

  // merge headings sx
  if (sxElements) {
    _.each(sxElements, (sxElement, sxKey) => {
      const key = sxKey as HeadingTags
      if (headingConfig?.[key]) {
        headingConfig[key].sx = { ...headingConfig[key].sx, ...sxElement }
      }
    })
  }

  return (
    <div className={className} sx={sxContainer}>
      <PrismicRichText
        field={content?.raw}
        components={{
          paragraph: ({ children }) => {
            if (paragraphStyleAsSpan)
              return <span sx={{ display: 'block' }}>{children}</span>

            return <Body sx={sxElements?.paragraph}>{children}</Body>
          },
          heading1: ({ children }) => (
            <Text {...headingConfig.h1}>{children}</Text>
          ),
          heading2: ({ children }) => (
            <Text {...headingConfig.h2}>{children}</Text>
          ),
          heading3: ({ children }) => (
            <Text {...headingConfig.h3}>{children}</Text>
          ),
          heading4: ({ children }) => (
            <Text {...headingConfig.h4}>{children}</Text>
          ),
          heading5: ({ children }) => (
            <Text {...headingConfig.h5}>{children}</Text>
          ),
          heading6: ({ children }) => (
            <Text {...headingConfig.h6}>{children}</Text>
          ),
          oListItem: ({ children, key }) => (
            <li
              sx={{
                ...body,
                display: undefined,
              }}
              key={key}
            >
              {children}
            </li>
          ),
          listItem: ({ children, key }) => (
            <li
              key={key}
              sx={{
                ...body,
                display: undefined,
              }}
            >
              {children}
            </li>
          ),
          hyperlink: ({ node, children, key }) => {
            const link_type = node?.data?.link_type

            if (link_type === 'Web') {
              return (
                <a
                  key={key}
                  sx={{ color: 'text' }}
                  href={node.data.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {children}
                </a>
              )
            }

            return (
              <Link
                sx={{ color: 'text' }}
                to={linkResolver(node.data)}
                key={key}
              >
                {children}
              </Link>
            )
          },
          image: ({ node, key }) => {
            const w = node.dimensions.width
            const h = node.dimensions.height
            const img = (
              <img
                src={node.url}
                width="100%"
                height={`${Math.round((100 * h) / w)}%`}
                title={node.alt ?? undefined}
                alt={node.alt ?? undefined}
                data-copyright={node.copyright ? node.copyright : undefined}
              />
            )

            return (
              <p key={key} className="block-img">
                {img}
              </p>
            )
          },
        }}
      />
    </div>
  )
}

export default RichText
