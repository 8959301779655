/** @jsxImportSource theme-ui */
import { FC } from 'react'
import {
  Heading,
  Text,
  HeadingProps,
  TextProps,
  ThemeUIStyleObject,
} from 'theme-ui'
import styled from '@emotion/styled'
import { css } from '@theme-ui/css'
import {
  caption,
  title,
  display,
  h4,
  h4Fit,
  h3,
  h3Fit,
  h2,
  h2Fit,
  h1,
  h1Fit,
  h5,
  h5Fit,
  h6,
  h6Fit,
  listingItem,
  bodyLarge,
  body,
  bodyFit,
  navigation,
  navigationFit,
  footerNavigation,
  ctaPrimary,
} from 'src/gatsby-plugin-theme-ui/text'
import { Col } from 'src/components/atoms/layout'
import { xs } from 'src/gatsby-plugin-theme-ui/utils'

export type StyledText = FC<{
  as?: string
  children?: React.ReactNode
  sx?: ThemeUIStyleObject
  className?: string
  onClick?: (e: any) => void
}>

/**
 * Helper method to make styled Heading components
 * @param displayName Used to name element in react dev tools
 */
export const makeHeading = (sx: ThemeUIStyleObject, displayName: string) => {
  const MyHeading: FC<HeadingProps> = ({ children, ...props }) => {
    return (
      <Heading sx={sx} {...props}>
        {children}
      </Heading>
    )
  }
  MyHeading.displayName = displayName

  return MyHeading as StyledText
}

/**
 * Helper method to make styled Text components
 * @param displayName Used to name element in react dev tools
 */
export const makeText = (
  sx: ThemeUIStyleObject,
  displayName: string,
  as: any = 'span'
) => {
  const MyText: FC<TextProps> = ({ children, ...props }) => {
    return (
      <Text sx={sx} as={as} {...props}>
        {children}
      </Text>
    )
  }
  MyText.displayName = displayName

  return MyText as StyledText
}

export const Title = makeHeading(title, 'Title')
export const H6 = styled('h6')(css(h6)) as StyledText
export const H6Fit = styled('h6')(css(h6Fit)) as StyledText
export const H5 = styled('h5')(css(h5)) as StyledText
export const H5Fit = styled('h5')(css(h5Fit)) as StyledText
export const H4 = styled('h4')(css(h4)) as StyledText
export const H4Fit = styled('h4')(css(h4Fit)) as StyledText
export const H3 = styled('h3')(css(h3)) as StyledText
export const H3Fit = styled('h3')(css(h3Fit)) as StyledText
export const H2 = styled('h2')(css(h2)) as StyledText
export const H2Fit = styled('h2')(css(h2Fit)) as StyledText
export const H1 = styled('h1')(css(h1)) as StyledText
export const H1Fit = styled('h2')(css(h1Fit)) as StyledText
export const Caption = styled('p')(css(caption)) as StyledText
export const Display = styled('h2')(css(display)) as StyledText
export const ListingItem = makeHeading(listingItem, 'ListingItem')
export const BodyLarge = makeText(bodyLarge, 'BodyLarge', 'p')
export const Body = styled('p')(css(body)) as StyledText
export const BodyFit = styled('p')(css(bodyFit)) as StyledText
export const CtaPrimary = makeText(ctaPrimary, 'CtaPrimary', 'span')
export const Navigation = makeText(navigation, 'Navigation', 'span')
export const NavigationFit = makeText(navigationFit, 'Navigation')
export const FooterNavigation = makeText(footerNavigation, 'Navigation')

export const Body10Cols: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Col start={1} end={xs(13, 11)}>
      <Body className={className}>{children}</Body>
    </Col>
  )
}

export const Body8Cols: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Col start={1} end={xs(13, 9)}>
      <Body className={className}>{children}</Body>
    </Col>
  )
}

export const BodyLarge10Cols: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Col start={1} end={xs(13, 11)}>
      <BodyLarge className={className}>{children}</BodyLarge>
    </Col>
  )
}

export const BodyLarge8ColsCenter: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Col start={xs(1, 3)} end={xs(13, 11)}>
      <BodyLarge className={className}>{children}</BodyLarge>
    </Col>
  )
}

export const BodyLarge11Cols: FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => {
  return (
    <Col start={1} end={xs(13, 12)}>
      <BodyLarge className={className}>{children}</BodyLarge>
    </Col>
  )
}

export const CircleSmall = styled('div')(
  css({
    fontSize: '16px',
    backgroundColor: 'text',
    color: 'background',
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: '32px',
    verticalAlign: 'middle',
  })
)
