/** @jsxImportSource theme-ui */
import { FC, useState, useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import { PrismicBottomBlockDataType } from 'root/graphql-types'
import { H2, H5 } from 'src/components/atoms/text'
import {
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import { Flex } from 'theme-ui'
import { motion } from 'framer-motion'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { keyframes } from '@emotion/react'
// @ts-ignore
import ArrowSmall from 'src/svg/arrow_small.svg'
import { h5 } from 'src/gatsby-plugin-theme-ui/text'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'src/components/atoms'
import { getPrismicImage, ImageProp } from 'src/utils/gatsby'

type BottomBlockProps = {
  data?: PrismicBottomBlockDataType
  lang: string
}

const arrowBounceLeft = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(0px);
  }
`
const arrowBounceRight = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(16px);
  }
  100% {
    transform: translateX(0px);
  }
`

const BottomBlock: FC<BottomBlockProps> = (props) => {
  const defaultData = useStaticQuery(graphql`
    {
      allPrismicFooter {
        nodes {
          lang
          data {
            extended_contact_title
            extended_contact_headline {
              html
            }
            extended_contact_us_link_title
            extended_contact_image {
              alt
              dimensions {
                height
                width
              }
              fluid(maxHeight: 400, maxWidth: 400) {
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  `)

  const lang = props?.lang || 'en'

  // const data = useMemo(() => {
  //   if (props.data) return props.data

  //   // return defaultData?.allPrismicFooter?.nodes?.[0].data
  //   return _.find(
  //     defaultData?.allPrismicFooter?.nodes,
  //     (node) => node.lang.indexOf(lang) > -1
  //   )?.data
  // }, [props.data, defaultData, lang])

  const data = {
    ..._.find(
      defaultData?.allPrismicFooter?.nodes,
      (node) => node.lang.indexOf(lang) > -1
    )?.data,
    ...props.data,
  }
  // const data = props.data
  //   ? props.data
  //   : _.find(
  //       defaultData?.allPrismicFooter?.nodes,
  //       (node) => node.lang.indexOf(lang) > -1
  //     )?.data

  const [forceRenderKey, setForceRenderKey] = useState('')

  useEffect(() => {
    setForceRenderKey('rerender')
  }, [])

  if (!data) return null

  const {
    extended_contact_title,
    extended_contact_headline,
    extended_contact_us_link_title,
    extended_contact_image,
  } = data

  return (
    <motion.div
      sx={{
        pt: ml('l', 'xxl'),
      }}
      {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 3 })}
    >
      <H5 as="div" sx={{ textAlign: 'center', mb: ml('m2', 'l2') }}>
        {extended_contact_title}
      </H5>
      <H2
        as="div"
        sx={{ display: 'block', textAlign: 'center', mb: ml('xl', 'l2') }}
      >
        {replaceHtml(extended_contact_headline?.html || '', 'p', 'div')}
      </H2>
      <Link
        resolveTo={{ type: 'contact' }}
        dataLayer={{
          event: 'Click',
          // pagePath: 'About Us ',
          // pageTitle: 'About',
          Text: 'Contact',
        }}
        sx={{ ...h5, textDecoration: 'none', textAlign: 'center' }}
      >
        <ArrowSmall
          sx={{
            mr: ml('m', 'm'),
            animation: `${arrowBounceLeft} 1s ease infinite`,
          }}
        />
        {extended_contact_us_link_title}
        <div
          sx={{
            display: 'inline-block',
            animation: `${arrowBounceRight} 1s ease infinite`,
          }}
        >
          <ArrowSmall sx={{ ml: ml('m', 'm'), transform: 'rotate(180deg)' }} />
        </div>
      </Link>
      {/* {JSON.stringify(extended_contact_image)} */}
      <Flex
        sx={{
          justifyContent: 'center',
          mb: ml('xl', 'xxl'),
          mt: ml('l', 'xxl'),
          width: '100%',
        }}
      >
        <GatsbyImage
          className="GatsbyImageClassName"
          sx={{
            width: 200,
            height: 200,
            display: 'flex',
            justifyContent: 'center',
          }}
          image={getPrismicImage(
            extended_contact_image as ImageProp,
            'fullWidth',
            'Growing plant'
          )}
        />
      </Flex>
    </motion.div>
  )
}

export default BottomBlock
