/** @jsxImportSource theme-ui */
import { Global } from '@emotion/react'
import theme from './index'
import { title, caption } from './text'

const mailchimpSx = {
  '#mc_embed_signup': {
    '#mce-email-error-response': {
      color: '#F95E65',
      marginTop: 4,
      height: 20,
      marginBottom: 8,
    },
    color: 'white',
    h2: {
      ...title,
    },
    a: {
      ...caption,
      color: '#999999',
    },
    p: {
      ...caption,
      margin: 0,
    },
    '.gray-text': {
      color: '#999999',
    },
    "input[type='checkbox']": {
      accentColor: '#DBFF00',
    },
    label: {
      ...caption,
      span: {
        marginLeft: 4,
        display: 'inline-block',
        transform: 'translateY(-2px)',
      },
    },
    '.email': {
      ...caption,
      lineHeight: '24px',
    },
    fieldset: {
      border: 'none',
      padding: 0,
      margin: 0,
      marginTop: 8,
      marginBottom: 16,
      input: {
        margin: 0,
      },
    },
    'input.email': {
      color: 'white',
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      borderBottomStyle: 'solid',
      borderBottomColor: 'white',
      borderBottomWidth: '1px',
      outline: 'none',
      '-webkit-box-shadow': 'none',
      '-moz-box-shadow': 'none',
      boxShadow: 'none',
      marginBottom: 0,
      '::placeholder': {
        color: 'white',
        opacity: 1,
      },
    },
    '#mc-embedded-subscribe': {
      color: '#DBFF00',
      fontSize: '16px',
      lineHeight: '16px',
      backgroundColor: 'transparent',
      padding: 0,
      marginTop: 16,
      border: 'none',
      cursor: 'pointer',
    },
  },
}

const GlobalStyles = () => {
  return (
    <Global
      styles={() => ({
        ...mailchimpSx,
        '*': {
          boxSizing: 'border-box',
        },
        '@font-face': {
          fontFamily: 'Lausanne',
          fontDisplay: 'swap',
          src: `url('/fonts/TWKLausanne-300.woff2') format('woff2'),
              url('/fonts/TWKLausanne-300.woff') format('woff'),
              url('/fonts/TWKLausanne-300.ttf') format('truetype')`,
        },
        body: {
          overflowX: 'hidden',
          msOverflowStyle: 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          '::-webkit-scrollbar': { display: 'none' },
        },
        '::-moz-selection': {
          background: theme?.colors?.primary,
          color: theme?.colors?.background,
        },

        '::selection': {
          background: theme?.colors?.primary,
          color: theme?.colors?.background,
        },
        '.video-js, .vjs-poster': {
          backgroundColor: 'transparent',
        },
        '.content__gdpr': {
          marginBottom: 16,
        },
      })}
    />
  )
}

export default GlobalStyles
