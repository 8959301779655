import { navigate } from 'gatsby'
import _ from 'lodash'
import SEO from 'src/components/organisms/SEO'
import Cookies from 'js-cookie'
import detectBrowserLanguage from 'detect-browser-language'

export const getLang = (staticPath?: string) => {
  let path = typeof window !== 'undefined' ? window?.location?.pathname : ''
  path = staticPath || path

  if (path.match(/^\/en/)) return 'en'

  // default is german
  return 'de'
}

export const getNodeByLang = (nodes: any, lang: string) => {
  const found = _.find(nodes, (node) => {
    const nodeLang = node?.lang || 'en'
    return nodeLang.indexOf(lang) > -1
  })

  return found || nodes?.[0]
}

const setCookieLang = (lang: string) => {
  const cookieLang = Cookies.get('language')

  if (cookieLang === lang) return

  if (lang.match(/^de/)) {
    Cookies.set('language', 'de', { expires: 365 })
  } else {
    Cookies.set('language', 'en', { expires: 365 })
  }
}

const getLangFromBrowser = (lang: string) => {
  if (lang.match(/^de/)) {
    return 'de'
  } else {
    return 'en'
  }
}

export const getUserLang = () => {
  const browserLang = detectBrowserLanguage()
  const cookieLang = Cookies.get('language')

  if (!cookieLang) {
    setCookieLang(browserLang)
    return getLangFromBrowser(browserLang)
  }

  return cookieLang
}

export const switchLang = () => {
  setCookieLang(getLang() === 'en' ? 'de' : 'en')
  navigate(SEO.getAlternateUrl())
}

export const setLang = (lang: 'en' | 'de') => {
  const currLang = getLang()

  if (lang === currLang) return

  switchLang()
}
