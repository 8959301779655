/** @jsxImportSource theme-ui */
import { FC, memo } from 'react'
import { ThemeUIStyleObject, Flex } from 'theme-ui'
import _ from 'lodash'

type AnimatedCounterProps = {
  className?: string
  textSx: ThemeUIStyleObject
  lineHeight: number | number[]
  digits: number
  count: number
}

type SingleDigitProps = {
  digit: number
  className?: string
  textSx: ThemeUIStyleObject
  lineHeight: number | number[]
}

const SingleDigit: FC<SingleDigitProps> = memo(
  ({ textSx, lineHeight, digit }) => {
    const transform = _.isNumber(lineHeight)
      ? `translateY(-${lineHeight * digit}px)`
      : [
          `translateY(-${lineHeight[0] * digit}px)`,
          `translateY(-${lineHeight[1] * digit}px)`,
          `translateY(-${lineHeight[2] * digit}px)`,
          `translateY(-${lineHeight[3] * digit}px)`,
          `translateY(-${lineHeight[4] * digit}px)`,
        ]

    return (
      <div sx={{ overflow: 'hidden', height: lineHeight }}>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            transform,
            transition: 'transform 1.5s cubic-bezier(0.76, 0, 0.24, 1)',
          }}
        >
          {_.times(10, (i) => (
            <div key={i} sx={{ ...textSx, display: 'block' }}>
              {i}
            </div>
          ))}
        </Flex>
      </div>
    )
  }
)

const AnimatedCounter: FC<AnimatedCounterProps> = ({
  textSx,
  lineHeight,
  digits,
  count,
  className,
}) => {
  const digitsArray = _.map(`${count}`.split(''), (digitString) =>
    parseInt(digitString)
  )

  const lengthDiff = digits - digitsArray.length
  const fill = lengthDiff > 0 ? Array(lengthDiff).fill(0) : []
  const filledDigits = [...fill, ...digitsArray]

  return (
    <Flex className={className}>
      {_.map(filledDigits, (val, i) => {
        return (
          <SingleDigit
            key={i}
            digit={val}
            textSx={textSx}
            lineHeight={lineHeight}
          />
        )
      })}
    </Flex>
  )
}

export default memo(AnimatedCounter)
