// import { PreviewStoreProvider } from 'gatsby-source-prismic'
import GlobalStyles from './src/gatsby-plugin-theme-ui/GlobalStyles'

export const wrapRootElement = ({ element }) => (
  <>
    {/* <PreviewStoreProvider initialEnabled={true}> */}
    <GlobalStyles />
    {element}
    {/*</PreviewStoreProvider> */}
  </>
)

export const shouldUpdateScroll = () => {
  setTimeout(() => window.scrollTo(0, 0), 1000)

  return false
}
