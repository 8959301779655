/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
// @ts-ignore
import MailChimpEmbedHtml from './MailChimpEmbed.html'
import { useGridColumnsWidth } from 'src/utils/useGridColumnsWidth'
import { gridMarginArray } from 'src/gatsby-plugin-theme-ui/sizes'
import { EE } from 'src/utils/eventEmitter'
import { Title, Caption } from 'src/components/atoms'
// @ts-ignore
import MailChimpClose from 'src/svg/mailchimp-close.svg'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'
// @ts-ignore
import serialize from 'form-serialize'
// @ts-ignore
import jsonp from 'jsonp'

const TITLE = "Let's stay connected"
const TITLE_SUBMITTED = 'Subscription Confirmed'
const SUBSCRIBE_POST =
  'https://hyam.us12.list-manage.com/subscribe/post-json?u=83bfd2dea319641bfc31eb8be&amp;id=3014ea896f&amp;v_id=6714&amp;f_id=005dbce0f0'
const THANK_YOU = 'Thank you for subscribing!'
const EMAIL_ERROR_MSG = 'Please re-type email'
const OTHER_ERROR_MSG = 'Please re-type email and check "Email" checkbox below'

export const MailChimpSignUp = () => {
  const getColumnsWidth = useGridColumnsWidth()
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const title = submitted ? TITLE_SUBMITTED : TITLE

  // https://stackoverflow.com/questions/8425701/ajax-mailchimp-signup-form-integration
  const submit = () => {
    const form = document.querySelector('#mc-embedded-subscribe-form')
    const formSerialized = serialize(form)

    const emailCheckboxEl = document.getElementById(
      'gdpr_54617'
    ) as HTMLInputElement | null
    const emailInputEl = document.getElementById(
      'mce-EMAIL'
    ) as HTMLInputElement | null
    const emailErrorEl = document.getElementById('mce-email-error-response')
    if (!emailErrorEl || !emailCheckboxEl || !emailInputEl) return

    if (!emailCheckboxEl.checked) {
      emailErrorEl.textContent = OTHER_ERROR_MSG
      return
    }

    jsonp(
      `${SUBSCRIBE_POST}&${formSerialized}`,
      { param: 'c' },
      (_err: any, data: any) => {
        const msg = data?.msg || ''

        emailErrorEl.textContent = ''

        // (1) subscription successful
        if (data?.result === 'success') {
          setSubmitted(true)
          setTimeout(() => setOpen(false), 2000)
          setTimeout(() => {
            setSubmitted(false)
            emailInputEl.value = ''
            emailCheckboxEl.checked = false
          }, 2500)
          return
        }

        // (2) subscription failure
        if (msg.match(/email/i)) {
          emailErrorEl.textContent = EMAIL_ERROR_MSG
        } else {
          emailErrorEl.textContent = msg || OTHER_ERROR_MSG
        }
      }
    )
  }

  useEffect(() => {
    EE.on('mailchimp-sign-up-open', () => setOpen(true))

    if (typeof document === 'undefined' || !document) return

    // @ts-ignore
    document
      .getElementById('mc-embedded-subscribe')
      .addEventListener('click', (e) => {
        e.preventDefault()
        submit()
      })
  }, [])

  return (
    <div sx={{ display: open ? 'block' : 'none' }}>
      <div
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 30010,
          width: sm('100vw', getColumnsWidth(5)),
          mr: sm(0, gridMarginArray),
          p: 'm',
          bg: 'text',
        }}
      >
        <Flex sx={{ width: '100%', justifyContent: 'space-between', mb: 's' }}>
          <Title as="div" sx={{ color: submitted ? 'footer' : 'background' }}>
            {title}
          </Title>
          <MailChimpClose
            sx={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        </Flex>
        <div
          sx={{ display: submitted ? 'none' : 'block' }}
          dangerouslySetInnerHTML={{ __html: MailChimpEmbedHtml }}
        />
        {submitted ? (
          <Caption sx={{ color: 'background' }}>{THANK_YOU}</Caption>
        ) : null}
      </div>
    </div>
  )
}
