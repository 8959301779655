/** @jsxImportSource theme-ui */
import { FC, memo, RefObject } from 'react'
import { AspectRatio } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getPrismicImage, ImageProp } from 'src/utils/gatsby'
import VideoPlayer, {
  VideoPlayerProps,
} from 'src/components/organisms/VideoPlayer'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { xs } from 'src/gatsby-plugin-theme-ui/utils'
import { PrismicImageType } from 'graphql-types'

type ResponsiveAssetProps = {
  imageDesktop?: ImageProp | PrismicImageType | null
  imageMobile?: ImageProp | PrismicImageType | null
  videoDesktop?: VideoPlayerProps | null
  aspectRatio?: number
  videoMobile?: VideoPlayerProps | null
  videoResolution?: 0 | 1 | 2 | 3 | 4 | 'default'
  className?: string
  videoRef?: RefObject<HTMLVideoElement>
  autoplay?: boolean
  muted?: boolean
  poster?: boolean
  showSoundToggle?: boolean
  loadVideoOnUserEvent?: boolean
}

type SingleVideoData = {
  video: {
    link?: string
  }
  image: {
    link?: string
  }
}

type ResponsiveVideoData = {
  sdLow?: SingleVideoData
  hdLow?: SingleVideoData
  sdHigh?: SingleVideoData
  hdHigh?: SingleVideoData
  width?: number
  height?: number
  name?: string
  description?: string
  duration?: number
}

const getVideoJsOpts = (
  videoMobile?: ResponsiveVideoData | null,
  videoDesktop?: ResponsiveVideoData | null,
  videoResolution = 'default' as 0 | 1 | 2 | 3 | 4 | 'default',
  breakpoint = 0
) => {
  const resolutionIndex = (() => {
    // mobile
    if (breakpoint === 0) return 0

    // responsive
    if (videoResolution === 'default') return breakpoint

    // custom
    return videoResolution
  })()

  const smallerResoluton = Math.min(resolutionIndex, breakpoint)

  // NOTE sdLow and sdHigh are not used
  const videoSrc0 = (() => {
    // mobile
    if (breakpoint === 0)
      return videoMobile?.hdLow?.video?.link || videoDesktop?.hdLow?.video?.link

    // desktop
    return videoDesktop?.hdLow?.video?.link
  })()

  const poster = (() => {
    // mobile
    if (breakpoint === 0)
      return (
        (videoMobile as any)?.pictures?.base_link ||
        (videoDesktop as any)?.pictures?.base_link
      )

    // desktop
    return (videoDesktop as any)?.pictures?.base_link
  })()

  // NOTE sdLow and sdHigh are not used
  const videoSrc = [
    videoSrc0,
    videoDesktop?.hdLow?.video?.link,
    videoDesktop?.hdLow?.video?.link,
    videoDesktop?.hdHigh?.video?.link,
    videoDesktop?.hdHigh?.video?.link,
  ][smallerResoluton]

  const width =
    breakpoint === 0 && videoMobile?.width
      ? videoMobile?.width
      : videoDesktop?.width
  const height =
    breakpoint === 0 && videoMobile?.height
      ? videoMobile?.height
      : videoDesktop?.height

  return {
    autoplay: true,
    preload: 'metadata',
    controls: false,
    muted: true,
    fluid: true,
    loop: true,
    name: videoDesktop?.name || videoMobile?.name,
    description: videoDesktop?.description || videoMobile?.description,
    duration: videoDesktop?.duration || videoMobile?.duration,
    poster,
    width: width || 100,
    height: height || 100,
    sources: [{ src: videoSrc }] as any,
  }
}

const validateSources = (
  imageDesktop: any,
  imageMobile: any,
  videoDesktop: any,
  videoMobile: any
) => {
  // desktop image
  if (imageDesktop && !imageMobile && !videoDesktop && !videoMobile) return true

  // desktop video
  if (!imageDesktop && !imageMobile && videoDesktop && !videoMobile) return true

  // desktop image and mobile image
  if (imageDesktop && imageMobile && !videoDesktop && !videoMobile) return true

  // desktop video and mobile video
  if (!imageDesktop && !imageMobile && videoDesktop && videoMobile) return true

  // desktop video and mobile image
  if (!imageDesktop && imageMobile && videoDesktop && !videoMobile) return true

  // desktop image and mobile video
  if (imageDesktop && !imageMobile && !videoDesktop && videoMobile) return true

  return false
}

const INVALID_SOURCES_MESSAGE = (message: string) => `INVALID SOURCES

Select one of the following combinations of assets in Prismic

- desktop image
- desktop video
- desktop image and mobile image
- desktop video and mobile video
- desktop video and mobile image
- desktop image and mobile video

${message}
`

const ResponsiveAsset: FC<ResponsiveAssetProps> = ({
  imageDesktop,
  imageMobile,
  videoDesktop,
  videoMobile,
  videoResolution = 'default',
  aspectRatio = 1,
  videoRef,
  autoplay = true,
  muted = true,
  poster = false,
  showSoundToggle = false,
  loadVideoOnUserEvent,
  ...props
}) => {
  const breakpoint = useBreakpointIndex()

  if (
    !validateSources(
      imageDesktop?.fluid,
      imageMobile?.fluid,
      videoDesktop,
      videoMobile
    )
  ) {
    const errorMessage = `CURRENT SOURCES

- desktop image  ${!!imageDesktop?.fluid}
- mobile image   ${!!imageMobile?.fluid}
- desktop video  ${!!videoDesktop}
- mobile video   ${!!videoMobile}`

    return (
      <div className={props.className}>
        <AspectRatio p="xs" m={0} ratio={aspectRatio} bg="primary">
          <pre sx={{ whiteSpace: 'pre-wrap', m: 0, color: 'background' }}>
            {INVALID_SOURCES_MESSAGE(errorMessage)}
          </pre>
        </AspectRatio>
      </div>
    )
  }

  const imageMobileWithDefault = (
    imageMobile?.fluid ? imageMobile : imageDesktop
  ) as ImageProp

  const hasImageMobile = !!imageMobile?.fluid
  const hasImageDesktop = !!imageDesktop?.fluid

  const videoSx = (() => {
    if (hasImageMobile) return { display: xs('none', 'block') }
    if (hasImageDesktop) return { display: xs('block', 'none') }

    return { display: 'block' }
  })()

  const alt = imageDesktop?.alt || imageMobile?.alt || ''

  // (1) mobile specific image
  if (breakpoint === 0 && hasImageMobile) {
    return (
      <div className={props.className}>
        <GatsbyImage
          image={getPrismicImage(imageMobileWithDefault, 'fullWidth', alt)}
          alt={alt}
          {...props}
        />
      </div>
    )
  }

  // (2) mobile video
  if (breakpoint === 0 && (videoMobile || videoDesktop)) {
    return (
      <div className={props.className}>
        <div
          sx={{
            ...videoSx,
          }}
        >
          <VideoPlayer
            {...getVideoJsOpts(
              videoMobile,
              videoDesktop,
              videoResolution,
              breakpoint
            )}
            videoRef={videoRef}
            autoplay={autoplay}
            muted={muted}
            showSoundToggle={showSoundToggle}
            loadOnUserEvent={loadVideoOnUserEvent}
          />
        </div>
      </div>
    )
  }

  // (3) desktop video
  if (breakpoint > 0 && videoDesktop) {
    return (
      <div className={props.className}>
        <div sx={videoSx}>
          <VideoPlayer
            {...getVideoJsOpts(
              undefined,
              videoDesktop,
              videoResolution,
              breakpoint
            )}
            videoRef={videoRef}
            autoplay={autoplay}
            muted={muted}
            showSoundToggle={showSoundToggle}
            loadOnUserEvent={loadVideoOnUserEvent}
          />
        </div>
      </div>
    )
  }

  // (4) mobile or desktop image
  if (imageDesktop) {
    return (
      <div className={props.className}>
        <GatsbyImage
          image={getPrismicImage(imageDesktop as ImageProp, 'fullWidth', alt)}
          alt={alt}
          {...props}
        />
      </div>
    )
  }

  return null
}

export default memo(ResponsiveAsset)
