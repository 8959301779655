import { ThemeUIStyleObject } from 'theme-ui'
import { ml } from './utils'

const textCommon: ThemeUIStyleObject = {
  m: 0,
  color: 'text',
  fontWeight: 300,
  letterSpacing: '0%',
  fontFamily: 'Lausanne, Helvetica',
  fontDisplay: 'swap',
  display: 'block',
}

export const display: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('180px', '380px'),
  lineHeight: ml('180px', '340px'),
  textTransform: 'uppercase',
}

export const h1: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('18px', '36px'),
  lineHeight: ml('25px', '44px'),
  textTransform: 'uppercase',
}

/** h1 that vertical aligns with uppercase characters. NOTE: Lausanne aligns
 * vertically with lower case characters as opposed to upper case */
export const h1Fit: ThemeUIStyleObject = {
  ...h1,
  transform: 'translate(0%, 9%)',
}

export const h2: ThemeUIStyleObject = {
  ...textCommon,
  'div:empty': {
    mb: ml('37px', '104px'),
  },
  fontSize: ml('27px', '100px'),
  lineHeight: ml('37px', '104px'),
  textTransform: 'uppercase',
}

/** h2 that vertical aligns with uppercase characters. NOTE: Lausanne aligns
 * vertically with lower case characters as opposed to upper case */
export const h2Fit: ThemeUIStyleObject = {
  ...h2,
  transform: 'translate(0%, 9%)',
}

export const h3: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('23px', '60px'),
  lineHeight: ml('32px', '72px'),
}
/** h3 that vertical aligns with uppercase characters. NOTE: Lausanne aligns
 * vertically with lower case characters as opposed to upper case */
export const h3Fit: ThemeUIStyleObject = {
  ...h3,
  transform: 'translate(0%, 10%)',
}

export const h4: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('23px', '60px'),
  lineHeight: ml('32px', '72px'),
  textTransform: 'uppercase',
}

/** h4 that vertical aligns with uppercase characters. NOTE: Lausanne aligns
 * vertically with lower case characters as opposed to upper case */
export const h4Fit: ThemeUIStyleObject = {
  ...h4,
  transform: 'translate(0%, 8%)',
}

export const h5: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('18px', '36px'),
  lineHeight: ml('25px', '44px'),
  textTransform: 'uppercase',
}
export const h5Fit: ThemeUIStyleObject = {
  ...h5,
  transform: 'translate(0%, 8%)',
}

export const h6: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('16px', '28px'),
  lineHeight: ml('22px', '34px'),
  textTransform: 'uppercase',
}
export const h6Fit: ThemeUIStyleObject = {
  ...h6,
  transform: 'translate(0%, 8%)',
}

export const navigation: ThemeUIStyleObject = {
  ...textCommon,
  display: 'inline-block',
  fontSize: ml('14px', '16px'),
  lineHeight: ml('14px', '16px'),
  letterSpacing: '0%',
  textTransform: 'uppercase',
  textDecoration: 'none',
}
/** navigation text that vertical aligns with uppercase characters.
 * NOTE: Lausanne aligns vertically with lower case characters as opposed to
 * upper case */
export const navigationFit: ThemeUIStyleObject = {
  ...navigation,
  transform: 'translate(0%, 9%)',
}

export const footerNavigation: ThemeUIStyleObject = {
  ...navigationFit,
  fontSize: ['12px', '12px', '14px', '16px', '16px'],
  lineHeight: ['12px', '12px', '14px', '16px', '16px'],
}

export const caption: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: '12px',
  lineHeight: '20px',
  textTransform: ml('uppercase', 'none'),
}

export const button = navigation

export const title: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('14px', '16px'),
  lineHeight: ml('22px', '24px'),
  textTransform: 'uppercase',
}

export const body: ThemeUIStyleObject = {
  ...textCommon,
  ':empty': {
    pb: 'l',
  },
  fontSize: ml('18px', '30px'),
  lineHeight: ml('25px', '40px'),
  wordBreak: 'break-word',
}

export const bodyLarge: ThemeUIStyleObject = {
  ...textCommon,
  ':empty': {
    mb: ml('m', 'l'),
  },
  fontSize: ml('26px', '60px'),
  lineHeight: ml('32px', '72px'),
}

export const bodySmall: ThemeUIStyleObject = {
  ...textCommon,
  ':empty': {
    pb: 'l',
  },
  fontSize: ml('14px', '20px'),
  lineHeight: ml('20px', '28px'),
  wordBreak: 'break-word',
}

export const bodyFit: ThemeUIStyleObject = {
  ...body,
  transform: 'translate(0%, 7%)',
}

export const listingItem: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('18px', '30px'),
  lineHeight: ml('25px', '32px'),
  mb: '8px',
}

export const ctaPrimary: ThemeUIStyleObject = {
  ...textCommon,
  fontSize: ml('20px', '28px'),
  lineHeight: ml('20px', '28px'),
}
