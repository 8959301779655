/** @jsxImportSource theme-ui */
import { FC, useState, useEffect, useRef } from 'react'
import { Flex } from 'theme-ui'
import _ from 'lodash'
import { Title } from 'src/components/atoms/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { motion, AnimatePresence } from 'framer-motion'
import { DURATION_Y } from 'src/animations'
import useIsVisible from 'src/utils/useIsVisible'
import useDimensions from 'react-cool-dimensions'
import HorizontalSliderMobile from './HorizontalSliderMobile'
import { useBreakpointIndex } from '@theme-ui/match-media'

type Element = { top: React.ReactNode; bottom: React.ReactNode }

const HorizontalSlider: FC<{ elements: Element[] }> = ({ elements }) => {
  const breakpoint = useBreakpointIndex()
  const [activeIndex, setActiveIndex] = useState(0)
  const activeIndexRef = useRef(activeIndex)
  activeIndexRef.current = activeIndex
  const numElements = elements.length
  const { ref, isVisible } = useIsVisible('HorizontalSlider')
  const { observe, height } = useDimensions({ useBorderBoxSize: true })

  const maxHeight = useRef(height || 0)
  if (height > maxHeight.current) maxHeight.current = height

  const containerHeight = (maxHeight.current || 448) + 'px'

  useEffect(() => {
    if (!isVisible) return

    const intervalId = setInterval(() => {
      const index = activeIndexRef.current
      const newIndex = (index + 1) % numElements

      setActiveIndex(newIndex)
    }, 5000)

    return () => clearInterval(intervalId)
  }, [numElements, isVisible])

  if (!elements?.[activeIndex]?.top) return null

  if (breakpoint < 2) return <HorizontalSliderMobile elements={elements} />

  return (
    <div ref={ref}>
      <div sx={{ position: 'relative', height: containerHeight }}>
        <AnimatePresence>
          {_.map(elements, (el, i) =>
            i === activeIndex ? (
              <motion.div
                key={i}
                animate={{ x: '0%' }}
                initial={{ x: '130%' }}
                exit={{ x: '-130%' }}
                transition={{
                  duration: DURATION_Y,
                  ease: [0.82, 0.27, 0.15, 0.81],
                }}
              >
                <div ref={observe} sx={{ position: 'absolute', top: 0 }}>
                  {el.top}
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <div>{el.bottom}</div>
                  </Flex>
                </div>
              </motion.div>
            ) : null
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default HorizontalSlider
