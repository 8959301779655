/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Flex, Button, ThemeUIStyleObject } from 'theme-ui'
import _ from 'lodash'
// @ts-ignore
import Cross from 'src/svg/mobile_navbar_cross.svg'
import { EE } from 'src/utils/eventEmitter'
import { Link } from 'gatsby'
import { h1Fit, navigation, button, h2 } from 'src/gatsby-plugin-theme-ui/text'
import { H2 } from 'src/components/atoms/text'
import { getLang, setLang } from 'src/utils/languageSwitch'
import Wink from 'src/components/atoms/Wink'
import { motion, useAnimation } from 'framer-motion'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'
import useLocalStorage from 'src/utils/useLocalStorage'
import AnimatedCounter from 'src/components/molecules/AnimatedCounter'
import { getPath } from './NavBarDesktop'

// @ts-ignore
import LogoLight from 'src/svg/hyam_logo_light.svg'

type NavBarMobileMenuProps = {
  show: boolean
  menuLinks: { name: string; link: string }[]
}

const MyLink: FC<{
  link: string
  name: React.ReactNode
  sx?: ThemeUIStyleObject
}> = ({ link, name, ...props }) => (
  <Link
    onClick={() => EE.emit('close-mobile-navbar')}
    to={getPath(link)}
    sx={{
      display: 'block',
      textDecoration: 'none',
    }}
    {...props}
  >
    {name}
  </Link>
)

const circleButton: ThemeUIStyleObject = {
  ...button,
  cursor: 'pointer',
  width: '48px',
  height: '48px',
  lineHeight: '46px',
}

const languageButtonEnabled: ThemeUIStyleObject = {
  ...circleButton,
  '&:hover': {
    bg: 'background',
    color: 'text',
  },
  bg: 'background',
  color: 'text',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderColor: 'background',
  borderWidth: '1px',
}

const languageButtonDisabled: ThemeUIStyleObject = {
  ...languageButtonEnabled,
  '&:hover': {
    bg: 'background',
    color: 'text',
  },
  bg: 'text',
  color: 'background',
}

const NavBarMobileMenu: FC<NavBarMobileMenuProps> = ({
  show = false,
  menuLinks,
}) => {
  const menuLinksMobile = _.drop(menuLinks)
  const [windowHeight, setWindowHeight] = useState(null as number | null)
  const [isSSR, setIsSSR] = useState(true)
  const [winkCount, setWinkCount, getWinkCount] = useLocalStorage<number>(
    'winkCount',
    0
  )

  const lang = getLang()
  const cardsControls = useAnimation()
  const buttonEnSx =
    lang === 'en' ? languageButtonEnabled : languageButtonDisabled
  const buttonDeSx =
    lang === 'de' ? languageButtonEnabled : languageButtonDisabled

  useEffect(() => {
    setIsSSR(false)
    setWindowHeight(window.innerHeight)

    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (show) {
      cardsControls.start(() => ({
        y: 0,
        transition: {
          duration: 0.5,
          delay: 0,
          ease: [0.07, 0, 0, 0.99],
        },
      }))
      cardsControls.start(() => ({
        opacity: 1,
        transition: {
          duration: 0,
        },
      }))
    } else {
      cardsControls.start(() => ({
        y: '100vh',
        transition: {
          duration: 0.5,
          delay: 0,
          ease: [0.07, 0, 0, 0.99],
        },
      }))
      cardsControls.start(() => ({
        opacity: 0,
        transition: {
          duration: 0,
          delay: 0.5,
        },
      }))
    }
  }, [show, cardsControls])

  useEffect(() => {
    const onOpen = () => {
      setWinkCount(getWinkCount())
    }
    EE.on('open-mobile-navbar', onOpen)

    return () => {
      EE.off('open-mobile-navbar', onOpen)
    }
  }, [getWinkCount, setWinkCount])

  if (isSSR) return null

  const onWinkClick = () => setWinkCount(winkCount + 1)

  return (
    <motion.div
      initial={{ y: '100vh', opacity: 0 }}
      animate={cardsControls}
      sx={{
        bg: 'text',
        width: '100%',
        height: '100vh',
        zIndex: 3002,
        display: sm('block', 'none'),
        position: 'fixed',
        pl: [
          'xsGridMargin',
          'sGridMargin',
          'mGridMargin',
          'lGridMargin',
          'auto',
        ],
        top: 0,
        left: 0,
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <MyLink
          sx={{ ...navigation, color: 'background', pt: '19px', pb: '14px' }}
          link=""
          name={<LogoLight />}
        />
        <div
          sx={{ px: '15px', pt: '14px', pb: '12px' }}
          onClick={() => EE.emit('close-mobile-navbar')}
        >
          <Cross sx={{ width: '18px', height: '18px' }} />
        </div>
      </Flex>
      <Flex
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'column',
          overflowY: 'auto',
          height: windowHeight
            ? `calc(${windowHeight}px - 47px)`
            : 'calc(100vh - 47px)',
          msOverflowStyle: 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          '::-webkit-scrollbar': { display: 'none' } /* WebKit */,
        }}
      >
        <div>
          {_.map(menuLinksMobile, ({ name, link }, key) => (
            <div
              key={key}
              sx={{
                py: '16px',
                mr: '15px',
                borderBottomColor: 'background',
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
              }}
            >
              <MyLink
                sx={{ ...h1Fit, color: 'background' }}
                link={link}
                name={name}
              />
            </div>
          ))}
        </div>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            pr: 'xsGridMargin',
          }}
        >
          <Flex
            sx={{ justifyContent: 'center', alignItems: 'center', pt: 'l' }}
          >
            <Button
              sx={{ ...buttonEnSx, mr: 'l', userSelect: 'none' }}
              onClick={() => {
                EE.emit('close-mobile-navbar')
                setLang('en')
              }}
            >
              EN
            </Button>
            <Wink
              mode="dark"
              sx={{ mr: 'l', transform: 'translateY(2px)' }}
              onClick={onWinkClick}
            />
            <Button
              sx={{ ...buttonDeSx, userSelect: 'none' }}
              onClick={() => {
                EE.emit('close-mobile-navbar')
                setLang('de')
              }}
            >
              DE
            </Button>
          </Flex>
          <Flex sx={{ mt: 'l', mb: 's' }}>
            <AnimatedCounter
              sx={{
                display: sm('flex', 'none'),
                mb: 'm',
                userSelect: 'none',
              }}
              count={winkCount}
              digits={0}
              textSx={{ ...h2, color: 'background' }}
              lineHeight={37}
            />
            <H2
              as="span"
              sx={{
                color: 'background',
                display: sm('block', 'none'),
                ml: 'xs',
              }}
            >
              {winkCount === 1 ? 'Wink' : 'Winks'}
            </H2>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  )
}

export default NavBarMobileMenu
