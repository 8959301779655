/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { ThinLine } from 'src/components/atoms/layout'
import { H2 } from 'src/components/atoms/text'
import { h2 } from 'src/gatsby-plugin-theme-ui/text'
import { PillsSelectRow } from 'src/components/atoms/buttons'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { motion } from 'framer-motion'
import {
  yOpacityProps,
  xSizeProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
} from 'src/animations'
import AnimatedCounter from 'src/components/molecules/AnimatedCounter'

type HeadingWithFiltersProps = {
  labels: { uid: string; data: { name: string } }[]
  selectedLabel: { uid: string; data: { name: string } }
  onClick: (label: { uid: string; data: { name: string } }) => void
  title: string
  count: number
  sx?: ThemeUIStyleObject
  titleTagName?: keyof JSX.IntrinsicElements
}

const HeadingWithFilters: FC<HeadingWithFiltersProps> = ({
  titleTagName = 'h2',
  labels,
  selectedLabel,
  onClick,
  title,
  count,
  ...props
}) => {
  return (
    <div {...props}>
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <H2 as={titleTagName}>{title}</H2>
          <AnimatedCounter
            count={count}
            digits={3}
            textSx={h2}
            lineHeight={ml(37, 104)}
          />
        </Flex>
      </motion.div>
      <motion.div
        {...xSizeProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
      >
        <ThinLine sx={{ my: ml('s', 'm') }} />
      </motion.div>
      <motion.div
        {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME * 2 })}
      >
        <PillsSelectRow
          sx={{ mb: ml('m', 'xxl') }}
          labels={labels}
          selectedLabel={selectedLabel}
          onClick={(label) => onClick(label)}
        />
      </motion.div>
    </div>
  )
}

export default HeadingWithFilters
