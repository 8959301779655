/** @jsxImportSource theme-ui */
import { FC, ReactNode } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Grid as ThemeUIGrid } from 'theme-ui'
import _ from 'lodash'
// @ts-ignore
import ArrowLarge from 'src/svg/arrow_large.svg'

export const GridGuide = () => {
  return (
    <ThemeUIGrid
      columns={12}
      gap={['xsGridGap', 'sGridGap', 'mGridGap', 'lGridGap', 'xlGridGap']}
      sx={{ mb: 's' }}
    >
      {_.times(12, (i) => (
        <div
          key={i}
          sx={{ width: '100%', height: '100px', backgroundColor: 'red' }}
        />
      ))}
    </ThemeUIGrid>
  )
}

export const InvisibleGridGuide = () => {
  return (
    <ThemeUIGrid
      columns={12}
      gap={['xsGridGap', 'sGridGap', 'mGridGap', 'lGridGap', 'xlGridGap']}
      sx={{ mb: 's' }}
    >
      {_.times(12, (i) => (
        <div
          className={`InvisibleGridGuideColumn_${i}`}
          key={i}
          sx={{ width: '100%', height: '0px', opacity: 0 }}
        />
      ))}
    </ThemeUIGrid>
  )
}

/**
 * Grid with predifined stylings
 */
export const Grid: FC<{
  columns?: number | number[]
  gap?: string[]
  sx?: ThemeUIStyleObject
  children: ReactNode
  start?: number
  end?: number
}> = ({
  children,
  columns = 12,
  gap = ['xsGridGap', 'sGridGap', 'mGridGap', 'lGridGap', 'xlGridGap'],
  ...props
}) => {
  return (
    <ThemeUIGrid columns={columns} gap={gap} {...props}>
      {children}
    </ThemeUIGrid>
  )
}

export function colSx<S, E>(gridColumnStart: S, gridColumnEnd: E) {
  return { gridColumnStart, gridColumnEnd }
}

/**
 * Single column using gridColumnStart and gridColumnEnd
 */
export const Col: FC<{
  start: number | number[]
  end: number | number[]
  children?: React.ReactNode
  sx?: ThemeUIStyleObject
}> = ({ start, end, children, ...props }) => {
  return (
    <Grid {...props}>
      <div sx={{ gridColumnStart: start, gridColumnEnd: end }}>{children}</div>
    </Grid>
  )
}

export const ThinLine: FC<{ sx?: ThemeUIStyleObject }> = (props) => {
  return (
    <div
      sx={{
        width: '100%',
        height: '1px',
        backgroundColor: 'text',
      }}
      {...props}
    />
  )
}

/**
 * A vertically centered ThinLine to use in the middle of two elements
 */
export const ThinLineMiddle: FC<{ sx?: ThemeUIStyleObject }> = (props) => {
  return (
    <Flex
      sx={{
        width: 'auto',
        alignSelf: 'stretch',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        mx: 'm',
      }}
      {...props}
    >
      <ThinLine />
    </Flex>
  )
}

export const Arrow: FC<{ sx?: ThemeUIStyleObject }> = (props) => {
  return (
    <Flex
      sx={{
        width: 'auto',
        // alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        height: '48px',
      }}
      {...props}
    >
      <ThinLineMiddle sx={{ mx: 0 }} />
      <Flex
        sx={{
          alignSelf: 'flex-end',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative',
          // flexGrow: 1
        }}
      >
        <ArrowLarge
          sx={{
            height: '48px',
            position: 'absolute',
            right: '-1px',
            bottom: '0px',
          }}
        />
      </Flex>
    </Flex>
  )
}
