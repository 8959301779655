/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import { keyframes } from '@emotion/react'
import { EE } from 'src/utils/eventEmitter'
// @ts-ignore
import EyesOpen from 'src/svg/cursor-eyes-open.svg'
// @ts-ignore
import EyesClose from 'src/svg/cursor-eyes-close.svg'

type Props = {
  icon: React.ReactNode
  eyesOpen?: boolean
  closeEyesOnClick?: boolean
  width?: number
  height?: number
}

const WIDTH = 80
const HEIGHT = 80

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const RotatingSvgWithEyes: FC<Props> = ({
  icon,
  eyesOpen: propsEyesOpen = true,
  closeEyesOnClick = false,
  width = WIDTH,
  height = HEIGHT,
}) => {
  const [eyesOpen, setEyesOpen] = useState(propsEyesOpen)

  useEffect(() => {
    setEyesOpen(propsEyesOpen)

    const onMouseDown = () => {
      if (closeEyesOnClick) {
        setEyesOpen(false)
      }
    }

    const onMouseUp = () => {
      if (closeEyesOnClick) {
        setTimeout(() => setEyesOpen(true), 100)
      }
    }

    EE.on('global-mouse-down', onMouseDown)
    EE.on('global-mouse-up', onMouseUp)

    return () => {
      EE.off('global-mouse-down', onMouseDown)
      EE.off('global-mouse-up', onMouseUp)
    }
  }, [closeEyesOnClick, propsEyesOpen])

  return (
    <div>
      <div
        sx={{
          width,
          height,
          zIndex: 1000,
          animation: `${rotateKeyframes} 2s linear infinite`,
        }}
      >
        {icon}
      </div>
      <div
        sx={{
          position: 'absolute',
          top: 0,
          width,
          height,
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width,
            height,
          }}
        >
          {eyesOpen ? <EyesOpen sx={{ width: 48, height: 48 }} /> : null}
          {!eyesOpen ? <EyesClose sx={{ width: 48, height: 48 }} /> : null}
        </Flex>
      </div>
    </div>
  )
}

export default RotatingSvgWithEyes
