import _ from 'lodash'
const space: Record<string, number> = {
  // Spacing
  xs: 8,
  s: 16,
  m: 24,
  m2: 32,
  l: 40,
  l2: 56,
  l3: 64,
  xl: 80,
  xxl: 104,
  xxl2: 208,

  // Line Heights
  h2Desktop: 44,
  h2Mobile: 38,

  // Grid System - Margins
  xsGridMargin: 15,
  sGridMargin: 20,
  mGridMargin: 40,
  lGridMargin: 80,
  xlGridMargin: 140,

  // Grid System - Gaps (Gutters)
  xsGridGap: 15,
  sGridGap: 20,
  mGridGap: 24,
  lGridGap: 40,
  xlGridGap: 40,

  // Other
  navBarHeightDesktop: 64,
  navBarHeightMobile: 48,
}

// create minus versions of all space sizes
_.each(space, (val, key) => {
  const minusKey = `-${key}`
  space[minusKey] = val * -1
})

const lineHeights = {
  navBarHeightDesktop: `${space.navBarHeightDesktop}px`,
  navBarHeightMobile: `${space.navBarHeightMobile}px`,
}

export const gridGapsArray = [
  'xsGridGap',
  'sGridGap',
  'mGridGap',
  'lGridGap',
  'xlGridGap',
]

export const gridGapsArrayMinus = [
  '-xsGridGap',
  '-sGridGap',
  '-mGridGap',
  '-lGridGap',
  '-xlGridGap',
]

export const gridMarginArray = [
  'xsGridMargin',
  'sGridMargin',
  'mGridMargin',
  'lGridMargin',
  'xlGridMargin',
]

export const singleFullWidthHeadingSx = [
  '-xsGridMargin',
  '-sGridMargin',
  '-mGridMargin',
  '-lGridMargin',
  'calc(-0.5 * (100vw - 1640px))',
]
export const fullWidthHeadingSx = {
  marginLeft: singleFullWidthHeadingSx,
  marginRight: singleFullWidthHeadingSx,
}

export { space, lineHeights }
