/** @jsxImportSource theme-ui */
import { FC, useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Flex } from 'theme-ui'
import _ from 'lodash'
import { keyframes } from '@emotion/react'
import { NavBarProps } from './types'
import { getLang } from 'src/utils/languageSwitch'
import { navigation } from 'src/gatsby-plugin-theme-ui/text'
import SineWave from 'src/components/atoms/SineWave'
import theme from 'src/gatsby-plugin-theme-ui'

// @ts-ignore
import LogoLight from 'src/svg/hyam_logo_light.svg'
// @ts-ignore
import LogoDark from 'src/svg/hyam_logo_dark.svg'

export const getPath = (link: string) => {
  const lang = getLang()

  let path = `/${link}`

  if (link && lang === 'en') {
    path = `/en/${link}`
  } else if (!link && lang === 'en') {
    path = `/en`
  }

  return path
}

const logos: Record<string, any> = {
  text: LogoDark,
  background: LogoLight,
}

const navInsetLeft = 'inset(0% 100% 0% 0%)'
const navInsetRight = 'inset(0% 0% 0% 100%)'
const navInsetVisible = 'inset(0% 0% 0% 0%)'
const navLinkEntersKeyframes = keyframes`
  to {
    clip-path: ${navInsetVisible};
  }
  from {
    clip-path: ${navInsetLeft};
  }
`
const navLinkLeavesKeyframes = keyframes`
  from {
    clip-path: ${navInsetVisible};
  }
  to {
    clip-path: ${navInsetRight};
  }
`
const navLinkEntersAnimation = `${navLinkEntersKeyframes} 0.5s cubic-bezier(0.45, 0, 0.55, 1)`
const navLinkLeavesAnimation = `${navLinkLeavesKeyframes} 0.5s cubic-bezier(0.45, 0, 0.55, 1)`

const LINK_MAP = {
  work: 1,
  about: 2,
  culture: 3,
  jobs: 4,
  contact: 5,
}

export const setActiveLink = (
  link: 'work' | 'about' | 'culture' | 'jobs' | 'contact' | null
) => {
  if (typeof window === 'undefined') return

  // NOTE: improve this, using global window as a quick solution.
  // @ts-ignore
  window.__activeLink__ = link
}

type GetActiveLink = () => number
const getActiveLink: GetActiveLink = () => {
  if (typeof window === 'undefined') return null

  // NOTE: improve this, using global window as a quick solution.
  // @ts-ignore
  return window.__activeLink__ ? LINK_MAP[window.__activeLink__] : null
}

const NavLink: FC<{
  link: string
  navTextColor: string
  name: string
  active: boolean
  disableWavyLine: boolean
  jsx: any
}> = ({ link, navTextColor, name, active, disableWavyLine, jsx }) => {
  const spanRef = useRef<HTMLDivElement>(null)
  const [spanWidth, setSpanWidth] = useState(0)
  const [animation, setAnimation] = useState('')
  const [clipPath, setClipPath] = useState(navInsetLeft)

  useEffect(() => {
    const newSpanWidth = spanRef?.current?.offsetWidth
    if (!newSpanWidth) return

    setSpanWidth(newSpanWidth)
  }, [])

  useEffect(() => {
    if (active) setClipPath(navInsetVisible)
    else setClipPath(navInsetLeft)
  }, [active])

  const onMouseEnter = () => {
    if (active) return

    setAnimation(navLinkEntersAnimation)
    setClipPath(navInsetVisible)
  }

  const onMouseLeave = () => {
    if (active) return

    setAnimation(navLinkLeavesAnimation)
    setClipPath(navInsetLeft)
  }

  return (
    <Link
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      to={getPath(link)}
      sx={{
        ...navigation,
        color: navTextColor,
        lineHeight: 'navBarHeightDesktop',
        position: 'relative',
      }}
    >
      <span ref={spanRef}>{jsx || name}</span>
      {jsx ? <span sx={{ display: 'none' }}>{name}</span> : null}
      {!disableWavyLine && (
        <div
          sx={{
            height: 64,
            clipPath,
            animation,
            position: 'relative',
            top: '-100%',
            overflow: 'hidden',
            msOverflowStyle: 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <div
            sx={{
              pointerEvents: 'none',
            }}
          >
            <SineWave
              width={spanWidth}
              color={(theme as any)?.colors[navTextColor]}
            />
          </div>
        </div>
      )}
    </Link>
  )
}

const NavBarDesktop: FC<NavBarProps> = ({ menuLinks, navTextColor, path }) => {
  const [activeLink, setActiveLink] = useState<number | null>(null)
  useEffect(() => {
    setActiveLink(getActiveLink())
  }, [path])

  const Logo = logos[navTextColor]

  return (
    <Flex
      sx={{ justifyContent: 'space-between', height: 'navBarHeightDesktop' }}
    >
      {_.map(menuLinks, ({ name, link }, index) => {
        return (
          <NavLink
            disableWavyLine={index === 0}
            active={index === activeLink}
            key={name}
            link={link}
            navTextColor={navTextColor}
            name={name}
            jsx={
              index === 0 && (
                <div sx={{ transform: 'translateY(1px)' }}>
                  <Logo />
                </div>
              )
            }
          />
        )
      })}
    </Flex>
  )
}

export default NavBarDesktop
