import { isBrowser } from 'src/utils/viewport'

export type DataLayerEvent = {
  event: string
  pagePath?: string
  pageTitle?: string
  Text: string
}

// Add dataLayer type to the window
declare global {
  interface Window {
    dataLayer: DataLayerEvent[]
  }
}

export const dataLayerPush = (dataLayer?: DataLayerEvent) => {
  if (dataLayer && isBrowser()) {
    window.dataLayer = window.dataLayer || []

    const data = {
      pagePath: window?.location?.href,
      pageTitle: window?.document.title,
      ...dataLayer,
    }
    // console.log('data', data)
    window.dataLayer.push(data)
  }
}
