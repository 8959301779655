/** @jsxImportSource theme-ui */
import { FC, ReactNode } from 'react'
import linkResolver from 'src/utils/linkResolver'
import { dataLayerPush, DataLayerEvent } from 'src/utils/gtm'

import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'

// FIXME: Wrong interface extension
export interface Props<TState> extends Omit<GatsbyLinkProps<TState>, 'to'> {
  dataLayer?: DataLayerEvent
  children: ReactNode
  resolveTo?: any
  to?: string
  onClick?: () => void
}

const Link: FC<Props<ReactNode>> = ({
  children,
  dataLayer,
  resolveTo,
  to,
  ...props
}) => {
  const restPropsAny: any = props

  const onClick = () => {
    dataLayerPush(dataLayer)

    if (props?.onClick) props?.onClick()
  }
  const link = resolveTo ? linkResolver(resolveTo) : to || ''
  if (!link) {
    return (
      <div sx={{ cursor: 'pointer' }} {...restPropsAny}>
        {children}
      </div>
    )
  }

  return (
    <GatsbyLink {...restPropsAny} to={link} onClick={onClick}>
      {children}
    </GatsbyLink>
  )
}

export default Link
