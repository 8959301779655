/** @jsxImportSource theme-ui */
import { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { EE } from 'src/utils/eventEmitter'
import { Property } from 'csstype'
import { Flex, Button, ThemeUIStyleObject } from 'theme-ui'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { H2, NavigationFit, FooterNavigation } from 'src/components/atoms/text'
import { ThinLine } from 'src/components/atoms/layout'
import {
  h2,
  navigation,
  footerNavigation,
  button,
} from 'src/gatsby-plugin-theme-ui/text'
import { sm, ml, xs } from 'src/gatsby-plugin-theme-ui/utils'
import { getLang, setLang } from 'src/utils/languageSwitch'

import Wink from 'src/components/atoms/Wink'
import linkResolver from 'src/utils/linkResolver'
import useLocalStorage from 'src/utils/useLocalStorage'
import { useBreakpointIndex } from '@theme-ui/match-media'
import AnimatedCounter from 'src/components/molecules/AnimatedCounter'
import { TwoRowsBottomLinks, LinkConfig } from './TwoRowsBottomLinks'

type FlexDirection = Property.FlexDirection

const circleButton: ThemeUIStyleObject = {
  ...button,
  width: sm('48px', '32px'),
  height: sm('48px', '32px'),
  lineHeight: sm('46px', '30px'),
}

const languageButtonDisabled: ThemeUIStyleObject = {
  ...circleButton,
  cursor: 'pointer',
  '&:hover': {
    bg: 'text',
    color: 'background',
  },
  bg: 'footer',
  color: 'text',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderColor: 'text',
  borderWidth: '1px',
}

const languageButtonEnabled: ThemeUIStyleObject = {
  ...languageButtonDisabled,
  bg: 'text',
  color: 'footer',
}

const BottomLinks: FC<{
  bottomLabels: any
  copyrightLabel: any
  lang: string
}> = ({ copyrightLabel, bottomLabels, ...props }) => {
  const lang = props?.lang || getLang()
  const breakpoint = useBreakpointIndex()
  const buttonEnSx =
    lang === 'en' ? languageButtonEnabled : languageButtonDisabled
  const buttonDeSx =
    lang === 'de' ? languageButtonEnabled : languageButtonDisabled
  const [winkCount, setWinkCount, getValue] = useLocalStorage<number>(
    'winkCount',
    0
  )
  const isSm = breakpoint < 1

  const onWinkClick = () => setWinkCount(winkCount + 1)

  useEffect(() => {
    const onCloseMobileNavBar = () => {
      setWinkCount(getValue())
    }
    EE.on('close-mobile-navbar', onCloseMobileNavBar)

    return () => {
      EE.off('close-mobile-navbar', onCloseMobileNavBar)
    }
  }, [getValue, setWinkCount])

  const [remountKey, setRemountKey] = useState('unmounted')
  useEffect(() => setRemountKey('mounted'), [])

  return (
    <Flex
      key={remountKey}
      sx={{
        pt: sm('l', 'm'),
        pb: 'm',
        justifyContent: 'space-between',
        flexDirection: sm('column', 'row') as FlexDirection[],
      }}
    >
      <Flex
        sx={{
          flexDirection: sm('column', 'row') as FlexDirection[],
          alignItems: 'center',
          borderBottomColor: sm('text', 'initial'),
          borderBottomWidth: sm('1px', 'initial'),
          borderBottomStyle: sm('solid', 'none'),
          mb: sm('m', 0),
        }}
      >
        <Flex
          sx={{
            justifyContent: sm('center', 'flex-start'),
            alignItems: 'center',
            mb: sm('m', 0),
          }}
        >
          <Button
            sx={{ ...buttonEnSx, mr: 'm', userSelect: 'none' }}
            onClick={() => setLang('en')}
          >
            EN
          </Button>
          {isSm ? (
            <Wink
              onClick={onWinkClick}
              sx={{
                mr: 'm',
                userSelect: 'none',
                transform: ml('translateY(1px)', 'translateY(2px)'),
              }}
            />
          ) : null}
          <Button
            sx={{ ...buttonDeSx, mr: isSm ? 0 : 'm', userSelect: 'none' }}
            onClick={() => setLang('de')}
          >
            DE
          </Button>
          {!isSm ? (
            <Wink
              onClick={onWinkClick}
              sx={{ transform: ml('translateY(1px)', 'translateY(2px)') }}
            />
          ) : null}
        </Flex>
        {/* DESKTOP */}
        <AnimatedCounter
          sx={{
            display: sm('none', 'flex'),
            ml: 'xs',
            userSelect: 'none',
            transform: 'translate(0%, 9%)',
          }}
          count={winkCount}
          digits={0}
          textSx={navigation}
          lineHeight={ml(14, 16)}
        />
        <NavigationFit sx={{ display: sm('none', 'block'), ml: '4px' }}>
          {winkCount === 1 ? 'Wink' : 'Winks'}
        </NavigationFit>
        {/* MOBILE */}
        <Flex>
          <AnimatedCounter
            sx={{ display: sm('flex', 'none'), mb: 'm', userSelect: 'none' }}
            count={winkCount}
            digits={0}
            textSx={h2}
            lineHeight={ml(37, 44)}
          />
          <H2 as="span" sx={{ display: sm('block', 'none'), ml: 'xs' }}>
            {winkCount === 1 ? 'Wink' : 'Winks'}
          </H2>
        </Flex>
      </Flex>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: sm('space-between', 'flex-end'),
        }}
      >
        {breakpoint > 1 && (
          <FooterNavigation sx={{ userSelect: 'none' }}>
            {copyrightLabel}
          </FooterNavigation>
        )}

        <FooterNavigation sx={{ 'margin-left': '27px', cursor: 'pointer' }}>
          <span
            onClick={() => {
              // @ts-ignore
              window._iub?.cs.api.openPreferences()
            }}
          >
            Cookies
          </span>
        </FooterNavigation>
        {_.map(bottomLabels, ({ link, title }) => {
          return (
            <Link
              to={linkResolver({ uid: link?.uid, lang })}
              key={title}
              sx={{ ...footerNavigation, ml: 'm', userSelect: 'none' }}
            >
              {title}
            </Link>
          )
        })}
      </Flex>
    </Flex>
  )
}

const mapLinks = (links: any) => {
  return _.map(links, (link) => ({
    link: {
      url: link?.link?.url ? link.link.url : linkResolver(link?.link),
    },
    title: link?.title,
  }))
}

type ServiceData = {
  service: {
    document: {
      data: {
        name: string
      }
    }
  }
}

const Footer: FC<{ lang: 'en' | 'de'; hideTopThinLine?: boolean }> = ({
  lang,
  hideTopThinLine = false,
}) => {
  const query = useStaticQuery(graphql`
    {
      allPrismicFooter {
        nodes {
          lang
          data {
            copyright_label
            newsletter_label
            services_title
            top_links {
              title
              link {
                type
                url
                uid
              }
            }
            bottom_labels {
              title
              link {
                url
                uid
                id
                slug
                lang
              }
            }
          }
        }
      }
    }
  `)

  const footerData = _.find(
    query.allPrismicFooter.nodes,
    (node) => node.lang.indexOf(lang) > -1
  )?.data

  //  const serviceLinks: { link: { url: string }; title: string }[] = _.map(
  //    services,
  //    ({ service, service_title }) => ({
  //      link: { url: linkResolver(service?.document) },
  //      title: service_title || service?.document?.data?.name,
  //    })
  //  )

  const { copyright_label, bottom_labels, newsletter_label, services_title } =
    footerData

  const middleLinkConfig: LinkConfig = {
    title: newsletter_label,
    link: { url: '' },
  }

  const top_links = footerData?.top_links || []

  const leftLinks = mapLinks(top_links.slice(0, 4))
  const rightLinks = mapLinks(top_links.slice(4, 9))

  return (
    <div>
      <TwoRowsBottomLinks
        hideTopThinLine={hideTopThinLine}
        left={leftLinks}
        collapsibleMenuTitle={services_title}
        right={rightLinks}
        middle={middleLinkConfig}
      />
      <ThinLine sx={{ mt: xs(0, 's') }} />
      <BottomLinks
        bottomLabels={bottom_labels}
        copyrightLabel={copyright_label}
        lang={lang}
      />
    </div>
  )
}

export default Footer
