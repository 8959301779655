/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Flex } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import { h2 } from 'src/gatsby-plugin-theme-ui/text'
import { H5 } from 'src/components/atoms/text'
import { blockScrollYMobile } from 'src/gatsby-plugin-theme-ui/utils'
import {
  PrismicServiceClientsGroupType,
  PrismicClientDataType,
} from 'root/graphql-types'
import { PrismicServicePageBodyCustomerLogosItemType } from 'root/graphql-types'
// @ts-ignore
import Cross from 'src/svg/cross_16px.svg'
import Sliding from 'src/components/molecules/Sliding'
import { getPrismicImage, ImageProp } from 'src/utils/gatsby'
import { Link } from 'src/components/atoms'

function arrayRepeat(a: any, repeats = 10) {
  let repeatArray = a
  _.times(repeats, () => (repeatArray = repeatArray.concat(a)))
  return repeatArray
}

type ClientsSliderProps = {
  heading: string
  clients?: PrismicServiceClientsGroupType[]
  itemsSlices?: PrismicServicePageBodyCustomerLogosItemType[]
}

type Item =
  | PrismicServiceClientsGroupType
  | PrismicServicePageBodyCustomerLogosItemType

const ClientsSlider: FC<ClientsSliderProps> = ({
  heading,
  clients,
  itemsSlices,
}) => {
  const isSlices = !_.isEmpty(itemsSlices)
  const items = isSlices ? itemsSlices : clients

  return (
    <div>
      {/*<H5 sx={{ 'text-align': 'center', mb: 's' }}>{heading}</H5>*/}
      <div sx={{ ...blockScrollYMobile(true), maxHeight: '198.56px' }}>
        <Sliding
          tagName="span"
          sx={{ ...h2, width: '100%' }}
          direction="RIGHT_TO_LEFT"
        >
          <div sx={{ display: 'inline' }}>
            {_.flatten(
              _.map(arrayRepeat(items, 2), (item: Item) => {
                const itemSlice =
                  item as PrismicServicePageBodyCustomerLogosItemType
                const itemClient = item as PrismicServiceClientsGroupType
                const itemClientData = itemClient?.client?.document
                  ?.data as PrismicClientDataType

                const logo = isSlices ? itemSlice?.logo : itemClientData?.logo

                return [
                  <Flex
                    sx={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '200px',
                      height: '200px',
                    }}
                  >
                    <Link resolveTo={itemSlice?.link?.document}>
                      <GatsbyImage
                        sx={{
                          width: 200,
                          height: 200,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        alt={logo?.alt || ''}
                        image={getPrismicImage(
                          logo as ImageProp,
                          'fullWidth',
                          ''
                        )}
                      />
                    </Link>
                  </Flex>,
                  <Cross sx={{ transform: 'translateY(-92px)', mx: 's' }} />,
                ]
              })
            )}
          </div>
        </Sliding>
      </div>
    </div>
  )
}

export default ClientsSlider
