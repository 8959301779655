import { useEffect, useState, useCallback } from 'react'

export const useGridColumnsWidth = () => {
  const [colWidth, setColWidth] = useState(0)
  const [gapWidth, setGapWidth] = useState(0)

  useEffect(() => {
    const doWork = () => {
      if (typeof document !== 'object') return

      const col0: HTMLDivElement | null = document.querySelector(
        '.InvisibleGridGuideColumn_0'
      )
      const col1: HTMLDivElement | null = document.querySelector(
        '.InvisibleGridGuideColumn_1'
      )

      if (!col0 || !col1) return

      setColWidth(col0.offsetWidth)
      setGapWidth(col1.offsetLeft - col0.offsetLeft - col0.offsetWidth)
    }

    doWork()
    window.addEventListener('resize', doWork)

    return () => window.removeEventListener('resize', doWork)
  }, [])

  const getWidth = useCallback(
    (numColumns: number) => {
      if (numColumns <= 0) return 0
      if (numColumns === 1) return colWidth

      const numGaps = numColumns - 1

      return numColumns * colWidth + numGaps * gapWidth
    },
    [colWidth, gapWidth]
  )

  return getWidth
}
