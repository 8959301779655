/** @jsxImportSource theme-ui */

import { FC, useRef, useEffect, useState, memo } from 'react'
import { keyframes } from '@emotion/react'

type SlidingProps = {
  tagName: string
  className?: string
  direction: 'LEFT_TO_RIGHT' | 'RIGHT_TO_LEFT'
  children: any
  speedFactor?: number
}
const Sliding: FC<SlidingProps> = ({
  children,
  tagName,
  className,
  direction,
  speedFactor = 150,
}) => {
  const animatedRef = useRef<HTMLDivElement>(null)
  const [animation, setAnimation] = useState('')
  const [duration, setDuration] = useState(0)
  const [retryWidth, setRetryWidth] = useState(0)

  const Tag = tagName as keyof JSX.IntrinsicElements

  useEffect(() => {
    if (!animatedRef?.current) return

    const width = animatedRef?.current?.offsetWidth
    setDuration(width / speedFactor)

    if (direction === 'LEFT_TO_RIGHT') {
      setAnimation(keyframes`
      from {
        transform: translate3d(-${width * 2}px, 0, 0);
      }
      to {
        transform: translate3d(-${width}px, 0, 0);
      }
    `)
    } else {
      setAnimation(keyframes`
      from {
        transform: translate3d(-${width}px, 0, 0);
      }
      to {
        transform: translate3d(-${width * 2}px, 0, 0);
      }
    `)
    }

    // width is incorrect first time useEffect runs, keep trying width is correct
    setTimeout(() => {
      const widthRetry = animatedRef?.current?.offsetWidth || 0
      if (widthRetry !== width) setRetryWidth(widthRetry)
    }, 1000)
  }, [animatedRef, direction, speedFactor, retryWidth])

  return (
    <span
      sx={{
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        animation: `${animation} ${duration}s linear infinite`,
      }}
      className={className}
    >
      <div ref={animatedRef} sx={{ display: 'inline-block' }}>
        {children}
      </div>
      <div sx={{ display: 'inline-block' }}>{children}</div>
      <div sx={{ display: 'inline-block' }}>{children}</div>
      <Tag sx={{ display: 'inline-block', marginBlock: 0 }}>{children}</Tag>
    </span>
  )
}

export default memo(Sliding)
