/** @jsxImportSource theme-ui */
import React, { FC, useState } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Flex } from 'theme-ui'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { ThinLine } from 'src/components/atoms/layout'
import _ from 'lodash'
import useDimensions from 'react-cool-dimensions'
import { EE } from 'src/utils/eventEmitter'
import { AnimatedUpDownArrow } from 'src/components/atoms'

type Element = {
  open: React.ReactNode
  close: React.ReactNode
}

type Props = {
  elements: Element[]
  closeContainerSx?: ThemeUIStyleObject
  itemOpenSx?: ThemeUIStyleObject
}

export const closeOnHoverProps = {
  onMouseEnter: () => {
    EE.emit('hover-accordion-close-all')
  },
}
export const openNextOnHoverProps = {
  onMouseEnter: () => {
    EE.emit('hover-accordion-open-next')
  },
}

const HoverAccordionMobileItem: FC<{
  el: Element
  index: number
  openEls: boolean[]
  setOpenEls: (els: boolean[]) => void
  closeContainerSx?: ThemeUIStyleObject
  itemOpenSx?: ThemeUIStyleObject
}> = ({ el, index, openEls, setOpenEls, closeContainerSx, itemOpenSx }) => {
  const isOpen = openEls[index]
  const isLast = index >= openEls.length - 1
  const { observe, height } = useDimensions({ useBorderBoxSize: true })

  return (
    <div key={index} sx={{ cursor: 'pointer' }}>
      <div
        sx={{ position: 'relative' }}
        onClick={() => {
          // close
          if (openEls[index]) {
            const newOpenEls = [...openEls]
            newOpenEls[index] = false
            setOpenEls(newOpenEls)
          }
          // open
          else {
            const newOpenEls = [...openEls]
            newOpenEls[index] = true
            setOpenEls(newOpenEls)
          }
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            ...closeContainerSx,
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              pr: 's',
            }}
          >
            {el.close}
          </Flex>
          <div
            sx={{
              width: '24px',
              height: '24px',
              transform: 'translateY(-15%)',
              position: 'relative',
              flexShrink: 0,
            }}
          >
            <AnimatedUpDownArrow open={isOpen} />
          </div>
        </Flex>
        <div
          sx={{
            transition: 'all 0.5s ease',
            height: isOpen ? height : 0,
            overflowY: 'hidden',
          }}
        >
          <div ref={observe} sx={{ pt: ml('m2', 's'), ...itemOpenSx }}>
            {el.open}
          </div>
        </div>
      </div>
      {isLast ? (
        <div sx={{ my: ml('m', 'l') }} />
      ) : (
        <ThinLine sx={{ my: ml('m', 'l') }} />
      )}
    </div>
  )
}

export const HoverAccordionMobile: FC<Props> = ({
  elements,
  closeContainerSx = {},
  itemOpenSx = {},
}) => {
  const [openEls, setOpenEls] = useState(_.map(elements, () => false))

  const els = _.map(elements, (el, index) => {
    return (
      <HoverAccordionMobileItem
        itemOpenSx={itemOpenSx}
        closeContainerSx={closeContainerSx}
        key={index}
        el={el}
        index={index}
        openEls={openEls}
        setOpenEls={setOpenEls}
      />
    )
  })

  return <div>{els}</div>
}
