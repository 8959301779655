/** @jsxImportSource theme-ui */

import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { getSchema } from './schemas'

const getLangAttr = (lang: string) => {
  if (!lang) {
    return 'en'
  }

  if (lang.match(/en/)) return 'en'
  if (lang.match(/de/)) return 'de'

  return 'en'
}

const getAlternateLangUrl = (
  siteUrl: string,
  alternate_language: any,
  currentLang: string
) => {
  if (!alternate_language) {
    if (currentLang === 'en') return siteUrl

    return `${siteUrl}/en`
  }

  const isDeAltLang = !!alternate_language.lang.match('de')
  const isEnAltLang = !!alternate_language.lang.match('en')
  const uid = alternate_language?.uid

  // german homepage
  if (!uid && isDeAltLang) {
    return siteUrl
  }
  // english homepage
  if (!uid && isEnAltLang) {
    return `${siteUrl}/en`
  }
  // german sub page
  if (uid && isDeAltLang) {
    return `${siteUrl}/${uid}`
  }
  // english sub page
  if (uid && isEnAltLang) {
    return `${siteUrl}/en/${uid}`
  }

  // default, just in case
  return siteUrl
}

type SeoProps = {
  title?: string | null
  description?: string | null
  image?: string | null
  path?: string | null
  lang: string
  is404?: boolean
  schema_type?: string
  alternate_languages: {
    uid: string
    lang: string
    url: string
  }[]
  faqSchemaItems?: any
}

function SEO({
  title = '',
  description,
  image,
  path,
  lang,
  alternate_languages,
  is404 = false,
  schema_type = '',
  faqSchemaItems,
}: SeoProps) {
  const { pathname: locationPathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const pathname = locationPathname || path
  const siteUrl = site.siteMetadata.siteUrl

  const langAttr = getLangAttr(lang)
  const altLangUrl = getAlternateLangUrl(
    siteUrl,
    alternate_languages?.[0],
    langAttr
  )
  const altLang = langAttr === 'en' ? 'de' : 'en'

  const seo = {
    lang: langAttr,
    title: title || '',
    description: description || '',
    image: image || '',
    url: pathname === '/' ? `${siteUrl}` : `${siteUrl}${pathname}`,
    // `
    altLang,
    altLangUrl,
  }

  // all pages in german
  if (altLang === 'de') {
    SEO.__alternatePath = `/${alternate_languages?.[0]?.uid || ''}`
  }
  // home page in english
  else if (!alternate_languages?.[0]?.uid) {
    SEO.__alternatePath = `/${altLang}`
  }
  // all other pages in english
  else {
    SEO.__alternatePath = `/${altLang}/${alternate_languages?.[0]?.uid || ''}`
  }
  const xDefaultHref =
    seo.lang === 'en' ? seo.url : `${siteUrl}${SEO.__alternatePath}`

  const isDevelop = process.env.GATSBY_PRISMIC_ENV === 'develop'
  const isNoIndex = is404 || isDevelop

  return (
    <Helmet
      htmlAttributes={{ lang: seo.lang }}
      title={seo.title}
      defaultTitle="HY.AM"
    >
      {isNoIndex && <meta name="robots" content="noindex" />}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />
      <link rel="alternate" hrefLang={seo.altLang} href={seo.altLangUrl} />
      <link rel="alternate" hrefLang={seo.lang} href={seo.url} />
      <link rel="alternate" hrefLang="x-default" href={xDefaultHref} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <script type="application/ld+json">
        {JSON.stringify(
          getSchema({
            url: seo.url,
            pathname,
            title: seo.title,
            image: seo.image,
            schema_type,
            faqSchemaItems,
          })
        )}
      </script>
    </Helmet>
  )
}

SEO.__alternatePath = ''
SEO.getAlternateUrl = () => {
  return SEO.__alternatePath || ''
}

export default SEO
