import { useEffect, useState, useCallback } from 'react'

type Node = HTMLDivElement | HTMLVideoElement | null

const useIsVisible = () => {
  const [isVisible, setIsVisible] = useState(false)

  const [node, setNode] = useState<Node>(null)

  const ref = useCallback((_node: Node) => {
    if (_node !== null) setNode(_node)
  }, [])

  useEffect(() => {
    if (!node) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) setIsVisible(true)
        else setIsVisible(false)
      },
      { threshold: [0.2] }
    )

    observer.observe(node)

    return () => observer.unobserve(node)
  }, [node])

  return { ref, isVisible }
}

export default useIsVisible
