/** @jsxImportSource theme-ui */

import { FC, useState } from 'react'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { PrismicImageType } from 'root/graphql-types'
import { H3 } from 'src/components/atoms'
import { Video } from 'src/components/atoms/Video'
import { heroSx, heroContentSx, heroVideoSx, soundOnSx } from './heroStyles'

const SOUND_ON = 'SOUND ON'
const SOUND_OFF = 'SOUND OFF'

type HeroProps = {
  imageDesktop?: PrismicImageType
  imageMobile?: PrismicImageType
  videoDesktop?: any
  videoMobile?: any
  text?: string | null
  hideSoundOn?: boolean
}

const Hero: FC<HeroProps> = (props) => {
  const { hideSoundOn } = props
  const { videoMobile, videoDesktop } = props
  const [audioMuted, setAudioMuted] = useState(true)

  const breakpoint = useBreakpointIndex()

  const src = breakpoint < 1 ? videoMobile?.hdLow : videoDesktop?.hdHigh
  const poster =
    breakpoint < 1
      ? videoMobile?.pictures?.base_link
      : videoDesktop?.pictures?.base_link

  return (
    <div sx={heroSx}>
      <div sx={heroContentSx}>
        <Video
          sx={heroVideoSx}
          poster={poster}
          autoPlay
          playsInline
          loop
          muted={audioMuted}
          src={src?.video?.link}
        />
      </div>
      {hideSoundOn ? null : (
        <H3
          as="div"
          sx={soundOnSx}
          onClick={() => setAudioMuted((prev) => !prev)}
        >
          {audioMuted ? SOUND_ON : SOUND_OFF}
        </H3>
      )}
    </div>
  )
}

export default Hero
