/** @jsxImportSource theme-ui */
import { FC, memo } from 'react'
import { keyframes } from '@emotion/react'
import { Link } from 'gatsby'
import { H5, H4Fit, CtaPrimary } from 'src/components/atoms/text'
import linkResolver from 'src/utils/linkResolver'
import { HoverAccordionMobile } from 'src/components/organisms/HoverAccordion/HoverAccordion'
import {
  PrismicAboutpageServicesGroupType,
  Maybe,
  PrismicService,
} from 'root/graphql-types'
import { Grid } from 'src/components/atoms/layout'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import { useBreakpointIndex } from '@theme-ui/match-media'
import ServicesItem from './ServicesItem'
import { dataLayerPush } from 'src/utils/gtm'
// @ts-ignore
import ArrowSmall from 'src/svg/arrow_small.svg'
import { RichText } from 'src/components/organisms'

type Props = {
  title: string | undefined | null
  services: Maybe<Maybe<PrismicAboutpageServicesGroupType>[]> | undefined
  seeMoreLabel: string
}

const arrowBounceLeftSm = keyframes`
  0% {
    transform: translateX(16px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(16px);
  }
`

const ServicesList: FC<Props> = ({ title, services = [] }) => {
  const breakpoint = useBreakpointIndex()
  return breakpoint <= 1 ? (
    <HoverAccordionMobile
      closeContainerSx={{ alignItems: 'center' }}
      elements={
        services?.map((service, i) => {
          const link = service?.service?.document

          return {
            close: <H4Fit>{service?.service_title}</H4Fit>,
            open: (
              <div key={i} sx={{ mb: 'xs', p: { my: 0 } }}>
                <Link sx={{ textDecoration: 'none' }} to={linkResolver(link)}>
                  <RichText
                    newHeadingsStyles
                    content={service?.service_description}
                  />

                  <div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mt: 'm',
                      textTransform: 'uppercase',
                    }}
                    onClick={() => {
                      dataLayerPush({
                        event: 'Click',
                        Text: (link as PrismicService)?.data?.name || '',
                      })
                    }}
                  >
                    <CtaPrimary sx={{ paddingTop: '5px' }}>
                      {service?.service_link_title || ''}
                    </CtaPrimary>
                    <div
                      sx={{
                        mr: ml('m', 'm'),
                        width: '50px',
                        animation: `${arrowBounceLeftSm} 1s ease infinite`,
                      }}
                    >
                      <ArrowSmall
                        sx={{
                          ml: 's',
                          verticalAlign: 'text-bottom',
                          transform: 'translateY(6%)', // center arrow with text vertically
                        }}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ),
          }
        }) || []
      }
    />
  ) : (
    <div>
      <Grid columns={2}>
        <div>
          <H5
            sx={{
              position: 'sticky',
              top: ml('xl', 'xxl'),
            }}
          >
            {title}
          </H5>
        </div>
        <div>
          {services?.map((service, index) => {
            return (
              <ServicesItem
                key={index}
                title={
                  service?.service_title ||
                  (service?.service?.document as PrismicService)?.data?.name ||
                  ''
                }
                descriptionRichText={service?.service_description}
                seeMoreLabel={service?.service_link_title || ''}
                link={service?.service?.document}
              />
            )
          })}
        </div>
      </Grid>
    </div>
  )
}

export default memo(ServicesList)
