import { useState, useEffect } from 'react'
import { isBrowser } from 'src/utils/viewport'

const useWindowScrollY = () => {
  const [windowHeight, setWindowHeight] = useState(
    isBrowser() ? window.scrollY : 0
  )
  useEffect(() => {
    if (isBrowser()) {
      // Handler to call on window resize
      const handleScroll = () => {
        // Set window width/height to state
        setWindowHeight(window.scrollY)
      }
      // Add event listener
      window.addEventListener('scroll', handleScroll)
      // Call handler right away so state gets updated with initial window size
      handleScroll()
      // Remove event listener on cleanup
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, []) // Empty array ensures that effect is only run on mount
  return windowHeight
}

export default useWindowScrollY
