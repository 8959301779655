/** @jsxImportSource theme-ui */
import { FC, useState } from 'react'
import _ from 'lodash'
import { Button, Flex, ThemeUIStyleObject } from 'theme-ui'
import { keyframes } from '@emotion/react'
import { navigation } from 'src/gatsby-plugin-theme-ui/text'
import { Navigation } from 'src/components/atoms/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'

type PillProps = {
  selected: boolean
  label: string
  onClick: () => void
  sx: any
}

const pillSlideKeyframes = keyframes`
  from {
    transform: translateY(4px);
  }
  to {
    transform: translateY(-32px);
  }
`

export const Pill: FC<PillProps> = ({ selected, label, onClick, ...props }) => {
  const [animationProp, setAnimationProp] = useState('')

  const buttonSx = {
    ...navigation,
    verticalAlign: 'middle',
    backgroundColor: selected ? 'text' : 'background',
    color: selected ? 'background' : 'text',
    '&:hover': {
      backgroundColor: 'text',
      color: 'background',
    },
    borderColor: 'text',
    borderWidth: '1px',
    borderStyle: 'solid',
    height: '32px',
    cursor: 'pointer',
    py: 0,
    px: 'm',
    borderRadius: 'l',
    transition: 'all 0.1s',
  }

  const fixedContainerSx: ThemeUIStyleObject = {
    height: '20px',
    overflow: 'hidden',
  }

  const animationContainerSx: ThemeUIStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateY(4px)',
    animation: animationProp,
  }

  return (
    <Button
      {...props}
      sx={buttonSx}
      onClick={() => {
        onClick()
        if (!selected) {
          setAnimationProp(
            `${pillSlideKeyframes} 0.5s cubic-bezier(0.45, 0, 0.55, 1)`
          )
          setTimeout(() => setAnimationProp(''), 600)
        }
      }}
    >
      <div sx={fixedContainerSx}>
        <div sx={animationContainerSx}>
          <span sx={{ display: 'block', pb: '20px' }}>{label}</span>
          <span sx={{ display: 'block' }}>{label}</span>
        </div>
      </div>
    </Button>
  )
}

type PillsRowProps = {
  sx: any
  labels: { uid: string; data: { name: string } }[]
  selectedLabel: { uid: string; data: { name: string } }
  onClick: (label: { uid: string; data: { name: string } }) => void
}

export const PillsSelectRow: FC<PillsRowProps> = ({
  labels,
  selectedLabel,
  onClick,
  ...props
}) => {
  return (
    <div {...props}>
      <Flex /* Desktop */ sx={{ display: ml('none', 'flex') }}>
        {_.map(labels, (label, key) => {
          return (
            <Pill
              sx={{ mr: 'l' }}
              key={key}
              label={label?.data?.name}
              selected={label?.uid === selectedLabel?.uid}
              onClick={() => onClick(label)}
            />
          )
        })}
      </Flex>
      <div /* Mobile */
        sx={{
          display: ml('block', 'none'),
          justifyContent: 'space-between',
          whiteSpace: 'nowrap',
          overflow: 'auto',
          msOverflowStyle: 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <div
          sx={{
            lineHeight: '48px',
            verticalAlign: 'middle',
          }}
        >
          {_.map(labels, (label, key) => {
            return (
              <Navigation
                key={key}
                onClick={() => onClick(label)}
                sx={{
                  pr: 'm',
                  textDecorationLine:
                    label === selectedLabel ? 'underline' : 'none',
                  display: 'inline-block',
                  // fine tune centering of Lausanne:
                  transform: 'translate(0,-9%)',
                  '&:hover': {
                    textDecorationLine: 'underline',
                  },
                  cursor: 'pointer',
                }}
              >
                {label?.data?.name}
              </Navigation>
            )
          })}
        </div>
      </div>
    </div>
  )
}
