import { space, lineHeights } from './sizes'

// TODO cleanup this file
const theme = {
  config: {
    // useColorSchemeMediaQuery: false,
    useLocalStorage: false,
  },
  space,
  sizes: space,
  radii: space,
  breakpoints: ['768px', '992px', '1280px', '1920px'],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Lausanne, Helvetica',
    monospace: 'Menlo, monospace',
  },
  fontSizes: { 0: 16, 1: 28, 2: 36, 3: 60, 4: 100, bodyLarge: 60 },
  fontWeights: {
    body: 300,
    heading: 'normal',
    bold: 700,
  },
  lineHeights,
  // TODO initialColorModeName is not working
  initialColorModeName: 'light',
  colors: {
    background: '#FFFFFF',
    error: '#F95E65',
    footer: '#DBFF00',
    text: '#111111',
    primary: '#1C01D1',
    secondary: '#30c',
    muted: '#f6f6f6',
    modes: {
      night: {
        text: '#111111',
        background: '#FFFFFF',
      },
      blue: {
        text: '#111111',
        background: '#00f',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
      textTransform: 'uppercase',
      fontStyle: 'normal',
      lineHeight: 'h1',
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
  },
  // variants for Heading and Text
  text: {
    // TODO delete weird
    weird: {
      color: 'red',
    },
    primary: {
      color: 'primary',
    },
    caps: {
      textTransform: 'uppercase',
    },
  },
  buttons: {
    primary: {
      color: 'background',
      bg: 'primary',
      '&:hover': {
        bg: 'text',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
  },
}

export default theme
