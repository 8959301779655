/** @jsxImportSource theme-ui */
import { useRef, useState, useEffect, FC } from 'react'
import { BodyLarge } from 'src/components/atoms/text'
import { ResponsiveAsset } from 'src/components/organisms'
import { motion, useAnimation } from 'framer-motion'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { PrismicImageType } from 'graphql-types'
import { useWindowSize, useWindowScrollY } from 'src/hooks'
import {
  DURATION_Y,
  cardsAnimationY,
  cardsAnimationOpacity,
} from 'src/animations'
import { isInViewport } from 'src/utils/viewport'

type ImageItem = {
  image: PrismicImageType
}

type Props = {
  text: string
  leftImages: ImageItem[]
  rightImages: ImageItem[]
}

const IndustriesEditorialText: FC<Props> = ({
  text,
  leftImages,
  rightImages,
}) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [imageContainerHeight, setImageContainerHeight] = useState(0)
  const [animationFinished, setAnimationFinished] = useState(false)
  const [initialYReached, setInitialYReached] = useState(false)
  const [imageTransformY, setImageTransformY] = useState(0)
  // Make the component responsive if manually resizing the viewport
  const windowsSize = useWindowSize()
  const controls = useAnimation()
  const windowScrollY = useWindowScrollY()

  const isVisible = isInViewport(textRef.current)

  useEffect(() => {
    if (textRef.current) {
      // Set the height of the image container to the same height of the text container
      setImageContainerHeight(textRef.current.offsetHeight)
    }
  }, [windowsSize])

  // Height of the image container
  const imageHeight = 100 / leftImages.length + '%'

  // const width = ml('11%', '14%')
  const width = ['20%', '16%', '11%', '14%', '14%']

  // Animation Y
  useEffect(() => {
    if (isVisible) {
      controls.start((i: number) =>
        cardsAnimationY({ i, y: '0px', delay: 0.25 })
      )
      controls.start((i: number) =>
        cardsAnimationOpacity({ i, opacity: 1, delay: 0.25 })
      )
      setTimeout(() => {
        setAnimationFinished(true)
      }, DURATION_Y * 1000)
    }
  }, [controls, isVisible])

  useEffect(() => {
    if (animationFinished && isVisible) {
      setImageTransformY((prev) => {
        const top = textRef?.current
          ? textRef?.current?.getBoundingClientRect().top / 5
          : 0

        if (!initialYReached && prev - top + 100 < 0) {
          return prev
        } else {
          setInitialYReached(true)
          return top - 100
        }
      })
    }
  }, [windowScrollY]) // eslint-disable-line

  return (
    <div sx={{ mt: 'xl' }}>
      {isVisible && (
        <div sx={{ position: 'relative', zIndex: -1 }}>
          <div
            sx={{
              position: 'absolute',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              height: imageContainerHeight,
            }}
          >
            {leftImages.map(({ image }, index) => {
              const isEven = index % 2 === 1
              return (
                <motion.div
                  key={index}
                  sx={{
                    width,
                    height: imageHeight,
                    ml: isEven ? '8.333%' : 0,
                  }}
                >
                  <div
                    sx={{
                      transform: `translateY(${imageTransformY}px)`,
                    }}
                  >
                    <ResponsiveAsset imageDesktop={image} imageMobile={image} />
                  </div>
                </motion.div>
              )
            })}
          </div>
          <div
            sx={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
              height: imageContainerHeight,
            }}
          >
            {rightImages.map(({ image }, index) => {
              const isEven = index % 2 === 1
              return (
                <motion.div
                  key={index}
                  sx={{
                    width,
                    mr: isEven ? '8.333%' : 0,
                    height: imageHeight,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                  }}
                >
                  <div
                    sx={{
                      transform: `translateY(${imageTransformY}px)`,
                    }}
                  >
                    <ResponsiveAsset imageDesktop={image} imageMobile={image} />
                  </div>
                </motion.div>
              )
            })}
          </div>
        </div>
      )}
      <div
        ref={textRef}
        sx={{
          width: '83%',
          margin: 'auto',
          zIndex: 2,
          textAlign: 'center',
          a: {
            color: 'text',
          },
        }}
      >
        {replaceHtml(text || '', 'p', BodyLarge)}
      </div>
    </div>
  )
}

export default IndustriesEditorialText
