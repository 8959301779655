/** @jsxImportSource theme-ui */
import { useRef, useState, FC } from 'react'
import ComponentWithCursorDesktop from 'src/components/molecules/ComponentWithCursor'
import { ResponsiveAsset } from 'src/components/organisms'
import { VideoPlayerProps } from 'src/components/organisms/VideoPlayer'
import { useResponsiveValue } from '@theme-ui/match-media'
import { sm, ml } from 'src/gatsby-plugin-theme-ui/utils'
import RotatingSvgWithEyes from 'src/components/molecules/RotatingSvgWithEyes'
import { motion } from 'framer-motion'
import {
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
  OFFSET_CARDS_Y,
  OFFSET_Y,
} from 'src/animations'
// @ts-ignore
import PlayVideo from 'src/svg/play-video-green.svg'
// @ts-ignore
import StopVideo from 'src/svg/stop-video-green.svg'

type Props = {
  videoDesktop: VideoPlayerProps | null
  videoMobile?: VideoPlayerProps | null
  autoplay?: boolean
  muted?: boolean
  poster?: boolean
  showSoundToggle?: boolean
  responsiveAssetSx?: any
  loadOnUserEvent?: boolean
}

const VideoWithCursor: FC<Props> = ({
  videoDesktop,
  videoMobile,
  autoplay = true,
  muted = true,
  poster = false,
  showSoundToggle = false,
  responsiveAssetSx = {},
  loadOnUserEvent,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(autoplay)
  const offsetCard = useResponsiveValue(sm(OFFSET_Y, OFFSET_CARDS_Y))
  return (
    <motion.div
      {...yOpacityProps({
        delay: DELAY_AFTER_SHUTTER + STAGGER_TIME,
        initialY: offsetCard,
      })}
    >
      <span
        onClick={() => {
          if (videoRef.current) {
            isPlaying ? videoRef.current.pause() : videoRef.current.play()
          }
          setIsPlaying((current) => {
            return !current
          })
        }}
      >
        <ComponentWithCursorDesktop
          cursorJsx={
            <RotatingSvgWithEyes
              eyesOpen={!isPlaying}
              icon={isPlaying ? <StopVideo /> : <PlayVideo />}
            />
          }
          cursorX={36}
          cursorY={36}
          useRelativeToElementPosition={false}
          component={
            <ResponsiveAsset
              imageDesktop={null}
              imageMobile={null}
              videoDesktop={videoDesktop}
              videoMobile={videoMobile}
              sx={{ pb: ml('l', 'xl'), ...responsiveAssetSx }}
              videoRef={videoRef}
              autoplay={autoplay}
              muted={muted}
              poster={poster}
              showSoundToggle={showSoundToggle}
              loadVideoOnUserEvent={loadOnUserEvent}
            />
          }
        />
      </span>
    </motion.div>
  )
}

export default VideoWithCursor
