import _ from 'lodash'
import React, { useRef, useEffect, useState } from 'react'
import useIsVisible from 'src/utils/useIsVisible'
import { EE } from 'src/utils/eventEmitter'

export type VideoProps = JSX.IntrinsicElements['video'] & {
  controlsRef?: { current: any }
  loadOnUserEvent?: boolean
}

function toBool(val: boolean | null | undefined, defaultValue: boolean) {
  if (val === undefined) {
    return defaultValue
  }
  return !!val
}

/**
 * Code taken from https://github.com/potato4d/react-muted-video-player
 */
export const Video: React.FC<VideoProps> = (props) => {
  const {
    autoPlay,
    playsInline,
    muted,
    controlsRef,
    loadOnUserEvent = true,
    ...p
  } = props

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { ref, isVisible } = useIsVisible()
  const [loadVideo, setLoadVideo] = useState(!loadOnUserEvent)

  useEffect(() => {
    if (!videoRef || !videoRef.current) return

    if (muted) {
      videoRef.current.setAttribute('muted', '1')
    } else {
      videoRef.current.removeAttribute('muted')
    }
  }, [muted])

  useEffect(() => {
    // (1) load on any user event
    let onAnyUserEventCalled = false
    const onAnyUserEvent = () => {
      document.removeEventListener('mousedown', onAnyUserEvent)
      document.removeEventListener('mousemove', onAnyUserEvent)
      document.removeEventListener('touchstart', onAnyUserEvent)
      document.removeEventListener('scroll', onAnyUserEvent)
      document.removeEventListener('keydown', onAnyUserEvent)

      if (onAnyUserEventCalled || loadVideo) return

      onAnyUserEventCalled = true
      setLoadVideo(true)
    }
    document.addEventListener('mousedown', onAnyUserEvent)
    document.addEventListener('mousemove', onAnyUserEvent)
    document.addEventListener('touchstart', onAnyUserEvent)
    document.addEventListener('scroll', onAnyUserEvent)
    document.addEventListener('keydown', onAnyUserEvent)

    // (2) load when visible
    if (!loadVideo && isVisible) {
      setLoadVideo(true)
    }
  }, [loadVideo, isVisible])

  if (!loadVideo) return <div ref={ref} />

  return (
    <video
      {...p}
      ref={(node: HTMLVideoElement) => {
        videoRef.current = node
        ref(node)
        if (_.isObject(controlsRef)) {
          controlsRef.current = node
        }
      }}
      autoPlay={toBool(autoPlay, true)}
      preload={toBool(autoPlay, true) ? 'auto' : 'none'}
      muted={muted}
      playsInline={toBool(playsInline, true)}
    />
  )
}
