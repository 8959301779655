/** @jsxImportSource theme-ui */
import { FC } from 'react'
import _ from 'lodash'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Grid, ThinLine } from 'src/components/atoms/layout'
import { navigationFit } from 'src/gatsby-plugin-theme-ui/text'
import Link from 'src/components/atoms/Link'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { HoverAccordionMobile } from 'src/components/organisms/HoverAccordion/HoverAccordion'
import { EE } from 'src/utils/eventEmitter'

export type LinkConfig = {
  title: string
  link: {
    url: string
  }
}

export type TwoRowsBottomLinksProps = {
  hideTopThinLine?: boolean
  left: LinkConfig[]
  middle: LinkConfig
  right: LinkConfig[]
  collapsibleMenuTitle: string
  middleOnClick?: () => void
}

export type SideLinksProps = {
  links: LinkConfig[]
  align: 'left' | 'right'
}

export const SingleLink: FC<{
  link: LinkConfig
  sx?: ThemeUIStyleObject
  onClick?: () => void
}> = ({ link, sx = {}, onClick }) => {
  return (
    <Link
      sx={{ ...navigationFit, ...sx }}
      to={link?.link?.url}
      onClick={onClick}
    >
      {link?.title}
    </Link>
  )
}

export const SideLinks: FC<SideLinksProps> = ({ links, align = 'left' }) => {
  return (
    <div>
      <Grid columns={2} sx={{ textAlign: align }}>
        <SingleLink link={links?.[0]} />
        <SingleLink link={links?.[1]} />
      </Grid>
      <ThinLine sx={{ my: 's' }} />
      <Grid columns={2} sx={{ textAlign: align }}>
        <SingleLink link={links?.[2]} />
        <SingleLink link={links?.[3]} />
      </Grid>
    </div>
  )
}

const TwoRowsBottomLinksMobile: FC<TwoRowsBottomLinksProps> = ({
  left,
  middle,
  right,
  collapsibleMenuTitle,
  middleOnClick,
}) => {
  const flexSx: ThemeUIStyleObject = {
    justifyContent: 'space-between',
    flexDirection: 'column',
  }

  return (
    <Flex sx={flexSx}>
      <div sx={{ pb: 'm', pt: 'm' }}>
        <SingleLink link={middle} onClick={middleOnClick} />
      </div>
      <ThinLine sx={{ mb: 'm' }} />
      <HoverAccordionMobile
        closeContainerSx={{ height: 14, alignItems: 'center' }}
        elements={[
          {
            close: (
              <Flex sx={{ ...navigationFit }}>{collapsibleMenuTitle}</Flex>
            ),
            open: _.map(right, (link, i) => {
              return (
                <div sx={{ mb: 'm2' }}>
                  <SingleLink link={link} key={i} />
                </div>
              )
            }),
          },
        ]}
      />
      <ThinLine />
      {_.map(left, (link, i) => {
        return (
          <div>
            <Flex sx={{ py: 'm' }}>
              <SingleLink link={link} key={i} />
            </Flex>
            {i < left?.length - 1 ? <ThinLine /> : null}
          </div>
        )
      })}
    </Flex>
  )
}

export const TwoRowsBottomLinks: FC<TwoRowsBottomLinksProps> = (props) => {
  const { left, middle, right, hideTopThinLine = false } = props
  const breakpoint = useBreakpointIndex()
  const isSm = breakpoint < 1

  if (isSm) {
    return (
      <TwoRowsBottomLinksMobile
        {...props}
        middleOnClick={() => EE.emit('mailchimp-sign-up-open')}
      />
    )
  }

  return (
    <div>
      {!hideTopThinLine ? <ThinLine /> : null}
      <Grid sx={{ pt: 's' }}>
        <div sx={{ gridColumnStart: 1, gridColumnEnd: 5 }}>
          <SideLinks links={left} align="left" />
        </div>
        <Flex
          sx={{
            gridColumnStart: 5,
            gridColumnEnd: 9,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SingleLink
            link={middle}
            onClick={() => EE.emit('mailchimp-sign-up-open')}
          />
        </Flex>
        <div sx={{ gridColumnStart: 9, gridColumnEnd: 13 }}>
          <SideLinks links={right} align="right" />
        </div>
      </Grid>
    </div>
  )
}
