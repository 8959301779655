import _ from 'lodash'
import menuConfig from 'src/generated/menuConfig.json'

const getPath = (lang: string, section: string, page?: string) => {
  const middle = _.get(menuConfig, `${section}.${lang}.slug`, section)

  let path = `/${middle}`
  if (lang === 'en') path = `/en/${middle}`

  return path
}

export default getPath
