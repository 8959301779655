/** @jsxImportSource theme-ui */
import parse, { domToReact } from 'html-react-parser'
import { Body } from 'src/components/atoms/text'
import _ from 'lodash'

export const replaceHtml = (html: string, tag: string, Component: any) => {
  if (!html) return null

  return parse(html, {
    replace: (domNode: any) => {
      if (domNode?.name === tag) {
        return <Component>{domToReact(domNode.children)}</Component>
      }
    },
  })
}

export const addTrailingWhiteSpaceToSpan = (
  html: any,
  tag = 'p',
  enabled = true
) => {
  if (!enabled) return html as string
  if (!html || !_.isString(html)) return ''

  return html.split(`</${tag}>`).join(` </${tag}>`)
}

/**
 *
 * @param string string containing line breaks
 * @param Component Component to wrap the string, e.g. Body, BodyLarge. Default is Body
 * @returns React node with the string converted to <p>, one per line
 */
export const toReact = (string: string, Component: any = Body) => {
  if (!string) return null

  //Add <p> and white space to empty <p> so there is a line break
  const text = `<p>${string.replace(/[\r\n]/gm, '</p><p>')}</p>`.replace(
    '<p></p>',
    '<p>&nbsp;</p>'
  )
  const html = replaceHtml(
    text, //Replace line breaks with <p>
    '',
    ''
  )
  return <Component>{html}</Component>
}
