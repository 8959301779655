/** @jsxImportSource theme-ui */
import { FC, useEffect, useState, memo, useMemo, useRef } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'

import WinkMobileMenuClick from 'src/svg/wink/mobile-menu/click.svg'
import WinkMobileClick from 'src/svg/wink/mobile/click.svg'
import WinkMobileMenuActive from 'src/svg/wink/mobile-menu/active.svg'
import WinkMobileMenuHover from 'src/svg/wink/mobile-menu/hover.svg'
import WinkMobileHover from 'src/svg/wink/mobile/hover.svg'

import WinkDesktopClick from 'src/svg/wink/desktop/click.svg'
import WinkDesktopActive from 'src/svg/wink/desktop/active.svg'
import WinkDesktopHover from 'src/svg/wink/desktop/hover.svg'

import { button } from 'src/gatsby-plugin-theme-ui/text'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'

const circleButton: ThemeUIStyleObject = {
  ...button,
  width: sm('48px', '32px'),
  height: sm('48px', '32px'),
  lineHeight: sm('46px', '30px'),
}

const Wink: FC<{
  onClick: () => void
  mode?: 'light' | 'dark'
  className?: string
}> = ({ onClick, mode = 'light', className }) => {
  const breakpoint = useBreakpointIndex()
  const [mounted, setMounted] = useState(false)
  const [state, setState] = useState('IDLE')
  const stateRef = useRef(state)
  let pointerUpTimeoutId: any = 0

  stateRef.current = state

  useEffect(() => setMounted(true), [])

  const jsx = useMemo(() => {
    // (1) mobile dark icons (navigation menu)
    if (breakpoint < 2 && mode === 'dark') {
      if (state === 'HOVER')
        return <WinkMobileMenuHover sx={{ ...circleButton }} />
      if (state === 'WINK')
        return <WinkMobileMenuClick sx={{ ...circleButton }} />

      return <WinkMobileMenuActive sx={{ ...circleButton }} />
    }

    // (2) mobile light icons (footer)
    if (breakpoint < 2) {
      if (state === 'HOVER') return <WinkMobileHover sx={{ ...circleButton }} />
      if (state === 'WINK') return <WinkMobileClick sx={{ ...circleButton }} />

      return <WinkMobileMenuHover sx={{ ...circleButton }} />
    }

    // (3) desktop
    if (breakpoint > 1) {
      if (state === 'HOVER')
        return <WinkDesktopHover sx={{ ...circleButton }} />
      if (state === 'WINK') return <WinkDesktopClick sx={{ ...circleButton }} />

      return <WinkDesktopActive sx={{ ...circleButton }} />
    }
  }, [state, breakpoint, mode])

  if (!mounted) return null

  return (
    <div
      sx={{ cursor: 'pointer' }}
      onPointerEnter={() => setState('HOVER')}
      onPointerLeave={() => {
        clearTimeout(pointerUpTimeoutId)
        if (stateRef.current === 'WINK') {
          setTimeout(() => setState('IDLE'), 200)
        } else {
          setState('IDLE')
        }
      }}
      onPointerDown={() => {
        clearTimeout(pointerUpTimeoutId)
        onClick()
        setState('WINK')
      }}
      onPointerUp={() => {
        clearTimeout(pointerUpTimeoutId)
        pointerUpTimeoutId = setTimeout(() => {
          if (stateRef.current === 'WINK') setState('HOVER')
        }, 200)
      }}
      className={className}
    >
      {jsx}
    </div>
  )
}

export default memo(Wink)
